import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import SubSection from './SubSection';
import { OrderingSelectors, OrderingConstants, OrderingOperations } from 'polygon-ordering';
import moment from 'moment';
import { RiArrowRightSLine } from 'react-icons/ri';
import { SALE_TYPE } from '../libs/polygon-ordering/src/constants/saleType';
import { setCurrentModal } from '../thunks/setCurrentModal';
import { DELIVERY_DETAILS_MODAL_ID } from '../modals/DeliveryDetailsModal';
import PickupTime from './PickupTime';
import RadioCheck from './RadioCheck';

const { setPickupTime, clearBuffer } = OrderingOperations;
const { ASAP_TIME } = OrderingConstants;
const { getSaleType, getPickupTime, getDeliveryTime, getFutureOrderingLimit, getLocation } =
  OrderingSelectors;

const TimeSummary = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const saleType = useAppSelector(getSaleType);
  const pickupTime = useAppSelector(getPickupTime);
  const deliveryTime = useAppSelector(getDeliveryTime);
  const duration = useAppSelector(getFutureOrderingLimit);
  const [showOptions, setShowOptions] = useState(false);
  const location = useAppSelector(getLocation) as POSLocation;

  const isPickupOrTakeAway =
    saleType === SALE_TYPE.CATERING ||
    saleType === SALE_TYPE.PICKUP ||
    saleType === SALE_TYPE.TAKE_AWAY ||
    saleType === SALE_TYPE.WEB_ORDERING;
  const isDelivery = saleType === SALE_TYPE.DELIVERY;

  const title =
    saleType === SALE_TYPE.CATERING ? t('timeSummary.selectPickupTime') : t('timeSummary.asap');

  const ORDER_TIME_INTERVAL = 5 * 60 * 1000; // 5 mins (ms)
  const DEFAULT_DELIVERY_DELAY = 10 * 60 * 1000; // 10 mins (ms)

  const [options, setOptions] = useState<
    {
      title: string;
      subtitle?: string;
      action: () => void;
      Icon: () => JSX.Element;
    }[]
  >([]);

  useEffect(() => {
    if (saleType === SALE_TYPE.CATERING) {
      setOptions([scheduleForLater]);
      setShowOptions(true);
    } else {
      setOptions([asapOption, scheduleForLater]);
    }
  }, [saleType, pickupTime]);

  const asapSubTitle = isDelivery
    ? t('title.deliveryAsapSubTitle')
    : t('title.pickupAsapSubTitle', {
        waitTime: location.averageOrderWaitTime || '30 - 40 mins',
      });

  const asapOption = {
    title: t('timeSummary.asap'),
    subtitle: t('title.deliveryAsapSubTitle'),
    action: () => {
      dispatch(setPickupTime(null));
    },
    Icon: () => <RadioCheck checked={pickupTime === undefined} size={35} />,
  };

  const scheduleForLater = {
    title: t('timeSummary.scheduleForLater'),
    action: () => {
      // calculate the time
      const defaultPickupDelay = new Date().getTime() + DEFAULT_DELIVERY_DELAY;
      // round the time to the nearest 5min interval
      const defaultPickupTime = new Date(
        Math.ceil(defaultPickupDelay / ORDER_TIME_INTERVAL) * ORDER_TIME_INTERVAL,
      );

      dispatch(setPickupTime(defaultPickupTime.toISOString()));
      dispatch(clearBuffer({}));
    },
    Icon: () => <RadioCheck checked={pickupTime !== undefined} size={35} />,
  };

  return (
    <div style={{ padding: '0 3.5em' }}>
      {!showOptions && (
        <SubSection
          title={
            isPickupOrTakeAway && (!pickupTime || pickupTime === ASAP_TIME)
              ? title
              : isDelivery && (!deliveryTime || deliveryTime === ASAP_TIME)
              ? title
              : moment(pickupTime || deliveryTime).format(
                  duration > 0 ? 'ddd, D MMM, YY h:mm a' : 'h:mm a',
                )
          }
          action={() => {
            if (saleType === SALE_TYPE.DELIVERY) {
              dispatch(
                setCurrentModal({
                  modalId: DELIVERY_DETAILS_MODAL_ID,
                  params: { allowRedirect: false },
                }),
              );
            } else {
              setShowOptions(!showOptions);
            }
          }}
          ActiveIcon={() => <RiArrowRightSLine />}
        />
      )}

      {showOptions &&
        options.map((option, index) => (
          <SubSection
            key={index}
            title={option.title}
            subtitle={option.subtitle}
            action={option.action}
            ActiveIcon={option.Icon}
          />
        ))}

      {(pickupTime || saleType === SALE_TYPE.CATERING) && (
        <PickupTime themeKey="orderPickupTimeSelect" reducedWidth={110} showTimeInErrorMessage />
      )}
    </div>
  );
};

export default TimeSummary;
