import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import TouchableOpacity from './TouchableOpacity';
import Text from './Text';
import { setEnableMultiPayments } from '../slices/enableMultiPayments';
import { OrderingSelectors } from 'polygon-ordering';
import { SALE_TYPE } from '../libs/polygon-ordering/src/constants/saleType';

const { getSaleType } = OrderingSelectors;

const PayWithMultiplePaymentsButton: React.FC = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const enableMultiPayments = useAppSelector(state => state.enableMultiPayments);
  const saleType = useAppSelector(getSaleType);
  const isDineIn = saleType === SALE_TYPE.TABLE_ORDER;

  return (
    <div>
      {!enableMultiPayments && !isDineIn && (
        <div className="text-center mt-4">
          <TouchableOpacity onClick={() => dispatch(setEnableMultiPayments(true))}>
            <Text value={t('payWithMultiplePayment')} style={{ fontSize: 12, color: 'red' }} />
          </TouchableOpacity>
        </div>
      )}
    </div>
  );
};

export default PayWithMultiplePaymentsButton;
