import React from 'react';
import { RiArrowDownSFill, RiArrowUpSFill, RiRefreshLine } from 'react-icons/ri';
import combineStyles from '../utils/combineStyles';
import { TEXT_PROPERTIES, CONTAINER_PROPERTIES } from '../utils/theme';

import getThemeLookup from '../selectors/getThemeLookup';

import TouchableOpacity from './TouchableOpacity';
import Text from './Text';
import { useAppSelector } from '../app/hooks';
import { IconType } from 'react-icons/lib';

interface IProps {
  themeKey?: string;
  children?: React.ReactNode;
  containerStyle?: React.CSSProperties;
  label?: string | JSX.Element;
  dynamicLabel?: string;
  disabled?: boolean;
  ariaLabel?: string;
  iconStyle?: React.CSSProperties;
  showCaret?: boolean;
  invisibleContainer?: boolean;
  labelStyle?: React.CSSProperties;
  leftIconStyle?: React.CSSProperties;
  rightIconStyle?: React.CSSProperties;
  isScrollChild?: boolean;
  showSpinner?: boolean;
  reverseCaret?: boolean;
  LeftIconComponent?: IconType | null;
  RightIconComponent?: IconType | null;
  onClick?: () => void;
  disabledStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  avertDefaultOpacity?: React.CSSProperties;
  rightContainer?: React.ReactNode;
  leftContainer?: React.ReactNode;
}

const StandardButton = React.forwardRef<HTMLDivElement, IProps>(
  (
    {
      containerStyle,
      label = '',
      dynamicLabel = '',
      onClick,
      themeKey,
      disabled,
      LeftIconComponent,
      RightIconComponent,
      iconStyle,
      labelStyle,
      leftIconStyle,
      rightIconStyle,
      showCaret,
      reverseCaret,
      showSpinner,
      children,
      invisibleContainer,
      isScrollChild,
      ariaLabel,
      avertDefaultOpacity,
      style: styleProp,
      rightContainer,
      leftContainer,
    },
    ref,
  ) => {
    const p = useAppSelector(getThemeLookup);

    const defaultThemeKey = 'defaultButton';
    const dynamicHeaderMemberButtonThemeKey = 'dynamicHeaderMemberButton';

    const caretStyle = combineStyles(
      p(defaultThemeKey, ['color', 'fontSize']),
      themeKey && p(themeKey, ['color', 'fontSize']),
    );

    const iconOnlyButton =
      (Boolean(RightIconComponent) || Boolean(LeftIconComponent)) &&
      !Boolean(label) &&
      !Boolean(children);

    return (
      <TouchableOpacity
        ref={ref}
        style={combineStyles(
          styles.mainContainer,
          p(defaultThemeKey, CONTAINER_PROPERTIES),
          themeKey && p(themeKey, CONTAINER_PROPERTIES),
          invisibleContainer && styles.invisibleContainer,
          containerStyle,
          styleProp ? styleProp : '',
          disabled ? p('bannerWithProblem', ['backgroundColor']) : '',
        )}
        onClick={onClick}
        disabled={disabled}
        disabledStyle={avertDefaultOpacity ? avertDefaultOpacity : styles.disabled}
        isScrollChild={isScrollChild}
        ariaLabel={ariaLabel}
      >
        {leftContainer && leftContainer}
        {LeftIconComponent && (
          <LeftIconComponent
            style={combineStyles(
              styles.leftIcon,
              iconOnlyButton && styles.iconOnly,
              p(defaultThemeKey, ['color', 'fontSize']),
              themeKey && p(themeKey, ['color', 'fontSize']),
              iconStyle,
              leftIconStyle,
            )}
          />
        )}

        {children ? (
          children
        ) : dynamicLabel ? (
          <Text
            style={combineStyles(
              p(defaultThemeKey, TEXT_PROPERTIES),
              p(dynamicHeaderMemberButtonThemeKey, ['fontSize']),
              themeKey && p(themeKey, TEXT_PROPERTIES),
              labelStyle,
            )}
          >
            {dynamicLabel}
          </Text>
        ) : (
          <Text
            style={combineStyles(
              styles.label,
              p(defaultThemeKey, TEXT_PROPERTIES),
              themeKey && p(themeKey, TEXT_PROPERTIES),
              labelStyle,
            )}
          >
            {label}
          </Text>
        )}

        {showCaret &&
          (reverseCaret ? (
            <RiArrowUpSFill style={caretStyle} />
          ) : (
            <RiArrowDownSFill style={caretStyle} />
          ))}

        {!showSpinner && RightIconComponent && (
          <RightIconComponent
            style={combineStyles(
              styles.rightIcon,
              iconOnlyButton && styles.iconOnly,
              p(defaultThemeKey, ['color', 'fontSize']),
              themeKey && p(themeKey, ['color', 'fontSize']),
              iconStyle,
              rightIconStyle,
            )}
          />
        )}

        {rightContainer && rightContainer}

        {showSpinner && (
          <RiRefreshLine
            className="spinner"
            style={combineStyles(
              styles.rightIcon,
              iconOnlyButton && styles.iconOnly,
              p(defaultThemeKey, ['color', 'fontSize']),
              themeKey && p(themeKey, ['color', 'fontSize']),
              iconStyle,
              rightIconStyle,
            )}
          />
        )}
      </TouchableOpacity>
    );
  },
);

const styles: Styles = {
  mainContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  disabled: {
    background: 'rgb(224 224 224)',
  },
  leftIcon: {
    marginRight: 5,
  },
  rightIcon: {
    marginLeft: 5,
  },
  iconOnly: {
    marginLeft: 0,
    marginRight: 0,
  },
  label: {
    textAlign: 'center',
  },

  invisibleContainer: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    border: 'none',
  },
};

export default StandardButton;
