// i18next documentation:
// https://www.i18next.com/translation-function/(interpolation|formatting|plurals|nesting|context)

import { OrderingConstants } from 'polygon-ordering';

import { INITIAL_SCREEN_ROUTE, MENU_SCREEN_ROUTE } from '../hooks/useRoutes';
import PasswordStrengthBar from 'react-password-strength-bar';

const {
  SALE_TYPES,
  PAYMENT_METHODS,
  PAYMENT_METHOD_UNAVAILABLE_REASON,
  ORDER_SUBMITTED,
  RECEIVED_AT_STORE,
  PROCESSED_AT_STORE,
  READY_TO_COLLECT,
  PICKED_UP_BY_DRIVER,
  DELIVERED,
  FAILURE_REASON,
  STOCK_BALANCE_THRESHOLDS,
} = OrderingConstants;

const strings: Strings = {
  de: {
    translation: {
      languages: {
        en: 'English',
        jp: '日本語',
        es: 'Español',
        de: 'Deutsch',
      },
      dailyIntake: {
        // it's a bit fuzzy whether these are correct, they say 2000-2500 kcal for men/women but it's all a recommended average anyway
        kilojoules: 'Die durchsnittliche tägliche Energieaufnahme eines Erwachsenen beträgt 8700kJ',
        kilojoulesAndCalories:
          'Die durchsnittliche tägliche Energieaufnahme eines Erwachsenen beträgt 8700kJ or 2100kcal',
        calories:
          'Die durchsnittliche tägliche Energieaufnahme eines Erwachsenen beträgt 8700kJ 2100kcal',
      },
      showItemDetails: 'Zeige Detials',
      joinLoyaltyProgram: 'Treten Sie unserem Treueprogramm an',
      joinLoyaltyProgramMessage:
        'Werden Sie Mitglied und erhanlten Sie exklusive Prämien und Vorteile',
      joinLoyaltyCallMessage:
        'Mit dieser Transaktion hätten Sie {{pointsearned}} Punkte verdienen können. Melden Sie sich jetzt an und wir senden Ihnen Mitglieder-exklusive Vorteile und Prämien.',
      registration: {
        verificationReminderModal: {
          title: 'Vielen Dank für Ihre Teilnahme am Treueprogramm',
          message: 'Wir senden Ihnen in Kürze eine E-Mail, in der Sie Ihr Konto bestätigen können.',
        },
      },
      favourite: {
        createNewFavouriteModal: {
          title: 'Neuen Favoriten erstellen',
          message: 'Möchten Sie diesen Favoriten benennen? Ein Name ist optional.',
          button: 'Erstellen',
        },
      },
      proofOfAgeTitle: 'Sind Sie älter als 18?',
      itemHasAlcohol:
        'Für den Erhalt dieses Artikels ist möglicherweise ein Altersnachweis notwendig.',
      checkoutItemHasAlcohol:
        'Für den Erhalt diese Artikeln ist möglicherweise ein Altersnachweis notwendig.',
      proofOfAgeMessage:
        'Bitte bestätig das Sie älter als 18 sind. Ein Altersnachweis ist möglicherweise notwendig diesen Artikel zu bekommen',
      cvcHelper: 'Bitte füllen Sie alle Felder aus um das CVC Feld aufzudecken',
      tieredLoyaltyPointsToThreshold:
        'Geben Sie {{currency}}{{points}} aus um Vortiele der {{tierName}} zu erhalten',
      maxValueWarning: 'Bitte beachten Sie, dass wir ein Online-Bestelllimit von {{amount}} haben',
      myGiftCardDetails: 'Meine Detials',
      deliveryDetails: 'Lieferdetails',
      recipientDetails: `Empfänger Details`,
      giftCardTopUpCustomValue: 'Werd nominieren',
      buyForMyself: 'Für mich selbst kaufen',
      buyAsGift: 'Als Geschenk kaufen',
      memberMoneyHeader: 'Mitgliedergeld',
      giftCardMemberBalance: 'Aktueller Kontostand: {{currency}}{{balance}}',
      addLoyaltyWallet: 'Zum Treue-Wallet hinzufügen',
      giftCardTopUp: 'Nachfüllen',
      giftCardHeader: 'Geschenkkarte',
      checkBalance: 'Kontostand Prüfen',
      purchaseGiftCard: 'Kauf Geschenkkarte',
      giftCarHeader: 'Geschenkkarte',
      updateSuccess: 'Ihre Daten wurden erfolgreich aktualisiert.',
      profileDetails: 'Persönliche Daten',
      profileHeader: 'Profil',
      updatePassword: 'PASSWORT AKTUALISIEREN',
      updatePasswordDetails: 'Aktualisieren Sie Ihr Passwort',
      deleteAccount: 'KONTO LÖSCHEN',
      deleteAccountDetails:
        'Sind Sie Sicher? Wenn Sie fortzetsen, weden alle Profilinformationen entfernt und die Kaufverlauf wird nicht mehr zugänglich',
      loyaltyRewardsPointsAway:
        'Sie sind {{remainingPoints}} Punkte entfernt von {{currency}}10 Mitgliedergeld',
      offersHeader: 'Angebote',
      claimReceiptSuccess: `Sie haben diese Quittung erfolgreich angefordert.`,
      claimMissingPointsInfo:
        'Geben Sie unten die Details ein, um die unzugewiesene Punkte zu bekommen',
      claimMissingPointsHeader: 'FEHLENDE PUNKE GELTEND MACHEN',
      transactionsHeader: 'LOYALITÄTSVERLAUF',
      replaceCardSuccess: 'Ihre Daten wurden aktualisiert.',
      replaceCardInfo:
        'Wenn Sie Ihre Karte verlegt haben, müssen Sie im Geschäft Ersatz besorgen. Um Ihre Punkte auf Ihre neue Karte zu übertragen, geben Sie die folgenden Details ein.',
      changeLanguageInfo: 'Bitte wählen Sie die gewünschte Sprache aus',
      allKey: 'Alle',
      replaceCardHeader: 'Karte ersetzen',
      changeLanguageHeader: 'Sprache Ändern',
      favouriteOrdersHeader: 'Lieblingsbestellungen',
      pastOrdersHeader: 'Bergangene Bestellungen',
      loyaltyWallet: 'ZUR TREUEBRIEFTASCHE HINZUFÜGEN',
      memberPoints: 'Mitgliedspunkte',
      memberMoney: 'Mitgliedsgeld',
      resetYourPasswordMessage:
        'An Ihre E-Mail-Adresse wurde eine E-Mail mit Anweisungen zum Zurücksetzen Ihres Passworts gesendet.',
      resetPasswordSubtitle:
        'Um Ihr neues Passwort festzulegen, geben Sie es bitte unten ein und bestätigen Sie es.',
      memberMoneyTitle:
        'Sehen Sie Ihren aktuellen Kontostand für Mitgliedspunkte und Mitgliedsgelder',
      memberPointsTitle: 'Sehen Sie Ihren aktuellen Kontostand für Mitgliedspunkte',
      loyaltyGreeting: 'Wilkommen',
      termsAndContition: 'Geschäftsbedingungen',
      termsPrefix: 'Ich stimme zu',
      moreInformation: 'Mehr Informationen',
      message: {
        maxCharactersEnd: ' Charakter',
        maxCharactersStart: 'Maximum ',
        minCharactersEnd: ' I agree to',
        minCharactersStart: 'Minimum ',
        savedAddressError: 'Unbekannter Fehlter aufgetreten',
        savedAddressRemoved: 'Erfolg',
      },
      form: {
        areRequired: 'Sind',
        isRequired: 'ist',
        required: 'Notwendig',
        requiredField: 'notwendig',
        mobileValidation: `Ungültige Telefon-nummer`,
        termsValidation: 'Sie müssen den Allgemeinen Geschäftsbedingungen zustimmen',
        validatePhoneNumbers: 'Es sind nur die Ziffern 0-9 gültig',
        emailValidation: 'Ungültige E-Mail-Adresse',
        memberNumberValidation: 'Ungültige Mitgliedsnr',
        invalidNumber: 'Ungültige Nummer',
        scheduleGiftcard: 'Datum und Uhrzeit müssen nach jetzt liegen',
        passwordTooShort: 'Zu Kurz',
        valuesMismatch: 'Werte stimmen nicht überein',
        passwordNotSecure: 'Passwort nicht sicher genug',
        onlyNumbersRequired: 'Nur Zahlen erforderlich',
      },
      memberNameFallback: 'Profil',
      loyaltyMoneyNameFallback: 'Mitgliedsgeld:',
      loyaltyPointsNameFallback: 'Punkte:',
      surchargeNameFallback: 'Zuschlag',
      tableNumber: 'Tisch Nummer',
      table: 'Tisch',

      fraudPreventionMessage: {
        collection:
          'Wir behalten uns das Recht vor, bei der Abholung von Online-Bestellungen einen Ausweis zu verlangen',
        delivery:
          'Wir behalten uns das Recht vor, bei der Lieferung von Online-Bestellungen nach einem Ausweis zu fragen',
      },
      emptyCartMessage: 'Ihr Warenkorb ist leer',
      removeFiltersWarnings: 'Versuchen Sie, einige Filter zu entfernen',
      noResultsInMenuSearchMessage: `Wir konnten keine Übereinstimmungen finden`,
      noLocationsMessage: 'Derzeit nehmen keine passenden Standorte Bestellungen entgegen',
      locationNotTakingOrders: 'Momentan nehmen wir keine Bestellungen entgegen',
      locationOpensAt: 'Geschlossen, öffnet um ',
      noKnownUserLocationMessage:
        'Bitte stellen Sie sicher, dass Sie das Geschäft ausgewählt haben, bei dem Sie bestellen möchten, da die Ortungsdienste nicht verfügbar sind',
      minimumOrderWithCouponMessage:
        'Geben Sie {{currency}}{{amount}} oder mehr aus, um die Mindestausgaben für den Gutschein zu erfüllen',
      minimumOrderWarningMessage: 'Mindestbestellmenge nicht erreicht',
      minimumExcludesSurchargeMessage: '*Zuzüglich Versandkosten',
      invalidStagedPurchasePresentMessage: 'Ungültiger Artikel im Warenkorb',
      incompatibleOfferMessage:
        'Angebot nicht mit Warenkorb kompatibel. Bitte entfernen Sie das Angebot oder fügen Sie einen kompatiblen Artikel hinzu',
      chargedMessage: {
        maybe:
          'Möglicherweise werden Sie berechnet. Bitte wenden Sie sich an den Shop, um das Problem zu beheben.',
        yes: 'Sie wurden berechnet. Bitte wenden Sie sich an den Shop, um das Problem zu beheben.',
        no: 'Sie wurden nicht berechnet.',
      },
      problemUpdatingKeyOrderProperty: 'Irgenwas ist schiefgelaufen. Bitte probiere nochmal.',
      problemFinalisingOrderMessage: 'Problem beim Abschließen der Bestellung.',
      problemWithReCaptchaMessage: 'Bei Ihrem reCAPTCHA-Versuch ist ein Problem aufgetreten',
      problemFetchingGiftCardMessage:
        'Beim Abrufen des Guthabens der Geschenkkarte ist ein Problem aufgetreten\nBitte überprüfen Sie Nummer und PIN',
      successFetchingGiftCardMessage: 'Geschenkkarte wurde nicht gefunden',
      badGiftCardMessage: 'Die PIN der Geschenkkarte stimmt nicht überein',
      paymentFailedMessage: 'Es gab ein Problem mit Ihrer Zahlung',
      noValidTimeMessage:
        'Bitte versuchen Sie es mit einem anderen Standort oder einer anderen Bestellart',
      asap: 'ASAP',
      noValidTime: '⚠️ Nicht verfügbar',
      fallbackToPickupMessage: 'Sie finden nicht, was Sie suchen?',
      invalidTime: '⚠️ Ungültig',
      flattenedDeliverySurcharge: 'Liefergebühr',
      offerTypeDivider: 'ODER',
      stagedPurchaseDiscount: 'Rabatt inbegriffen',
      checkoutSignInPrompt: 'ANMELDEN',
      checkoutSignInPromptReason: {
        guestOrderingDisabled: 'Bitte melden Sie sich an, um Ihre Bestellung aufzugeben',
        guestOrderingEnabled: 'Um Prämien zu verdienen und einzulösen',
      },
      maximumOrderAmountLabel: 'MAXIMUM',
      minimumOrderAmountLabel: 'MINIMUM',
      brandsEducationMessage:
        'An diesem Standort sind mehrere Marken verfügbar. Verwenden Sie die Markenmenüleiste, um zwischen ihnen zu wechseln und anzuzeigen, was verfügbar ist. Sie können Artikel jeder dieser Marken in Ihren Warenkorb legen.',
      preSaleMessage: {
        title: {
          [SALE_TYPES.PICKUP]: '',
          [SALE_TYPES.DELIVERY]: '',
          [SALE_TYPES.DINE_IN]: '',
          [SALE_TYPES.TAKE_AWAY]: '',
          [SALE_TYPES.CATERING]: '',
          [SALE_TYPES.TABLE_ORDER]: '',
        },
        message: {
          [SALE_TYPES.PICKUP]: '',
          [SALE_TYPES.DELIVERY]: '',
          [SALE_TYPES.DINE_IN]: '',
          [SALE_TYPES.TAKE_AWAY]: '',
          [SALE_TYPES.CATERING]: '',
          [SALE_TYPES.TABLE_ORDER]: '',
        },
      },
      unavailablePurchasesWarning: {
        title: '',
        text: 'Einige Artikel in Ihrem Warenkorb sind nicht verfügbar. Weitere Informationen finden Sie in Ihrem Warenkorb.',
      },
      mfaHelperText: 'Bitte überprüfen Sie Ihre SMS oder E-Mail auf den Code',
      deliveryEstimateFailedMessage: 'Es ist ein Problem aufgetreten ({{reason}})',
      saveCardLabel: 'Erinnern',
      allBrandsLabel: 'Alle',
      deliveryTrackingLink: 'Verfolgen Sie Ihre Bestellung',
      locationSearchResult: '{{count}} Standort',
      locationSearchResult_plural: '{{count}} Standorte',
      eta: 'Erwartete Lieferzeit',
      editDeliveryDetails: 'Details bearbeiten',
      deliveryTimeMayVaryMessage:
        'Bitte beachten Sie, dass die voraussichtliche Lieferzeit je nach Bestellwert variieren kann',
      creditCardMinimumNotMetMessage: 'Der Mindestbetrag der Kreditkarte wurde nicht erreicht',
      offersIfNotVerified: 'Angebote nur für verifizierte Mitglieder verfügbar',
      profileVerificationText: 'Ihr Profil ist nicht verifiziert. Bitte verifier ihren Konto!',
      deleteAccountIfNotVerified: 'Nur verifizierte Mitglieder können ihre Konten löschen',
      favouriteAccountIfNotVerified:
        'Lieblingsbestellungen sind nur für verifizierte Mitglieder verfügbar',
      claimReceiptsIfNotVerified:
        'Sobald Sie Ihr Konto verifiziert haben, können Sie Ihre Quittung anfordern!',
      purchaseStaged: {
        new: 'Zur Bestellung hinzugefügt',
        modified: 'Zur Bestellung gespeichert',
      },
      paymentOption: {
        now: 'Schick jetzt',
        later: 'Sende später',
      },
      placeholder: {
        firstName: 'Vorname',
        lastName: 'Nachname',
        email: 'Email',
        confirmEmail: 'E-Mail bestätigen',
        message: 'Nachricht',
        giftcardAmount: 'Menge',
        verifyPin: 'Verifier PIN',
        receiptNo: 'Verkaufs-ID',
        saleTotal: 'Verkaufsmenge',
        transactionTime: 'Kaufzeit',
        transactionDate: 'Kauf Datum',
        storeID: 'Standort',
        loyaltyDatePicker: 'Kaufdatum',
        verificationCode: 'Verifizierungs Code',
        memberNo: 'Mitgliedsnummer',
        giftCardNo: 'Geschenkkartenummer',
        locationSearch: 'Such',
        menuSearch: 'Durchsuchen dieses Menü',
        promoCode: 'ANGEBOTSCODE',
        memberOffers: 'KEIN ANGEBOT',
        tableNumber: 'ERFORDERLICH',
        orderNotes: 'Gibt es spezielle Anweisungen für uns?',
        deliveryNotes: 'Irgendwelche Hinweise für Ihren Fahrer?',
      },
      title: {
        asapSubTitle: 'Geschätzte 30-40 Minuten',
        loyaltyPointsEarned: 'Punke verdient',
        loyaltyTotal: 'Gesamt',
        loyaltyPrice: 'Preis',
        loyaltyPoints: 'Punkte',
        loyaltyQuantity: 'Menge',
        loyaltyDescriptionHeader: 'Beschreibung',
        filterSaleType: 'Verkaufsart',
        loyaltyDateString: 'Datum',
        loyaltyTimeString: 'Zeit',
        filter: 'Filter',
        pastOrdersSaleID: 'Verkaufs-ID',
        modal: {
          signIn: 'Anmelden',
          resetPassword: 'Setze dein Passwort zurück',
          offer: {
            offerPresent: 'Ausgewähltes Angebot',
            offerAbsentOffersPresent: 'Angebot anwenden',
            offerAbsentOffersAbsent: 'Code eingeben',
          },
          brandsEducation: 'Wussten Sie?',
          memberRegister: 'Registrierung des Treuemitgliedschaftssystems',
        },
        newOrder: 'Neue Bestellung',
        forYou: 'Für Sie',
        sizeChoice: 'Größe',
        saleType: 'Auftragsart',
        location: 'Standort',
        cart: 'Wagen',
        offer: 'Angebot',
        notes: 'Bestellhinweise',
        deliveryNotes: 'Fahrerhinweise',
        pickupTime: 'Abholzeit',
        memberBalances: 'Aktueller Kontostand',
        memberBalances_plural: 'Aktuelle Kontostanden',
        paymentMethod: 'Zahlungsarten',
        purchaserDetails: 'Ihre Daten',
        deliveryAddress: 'Ihre Adresse',
        deliveryTime: {
          desired: 'Gewünschte Lieferzeit',
          estimated: 'Voraussichtliche Lieferzeit',
        },
        messages: 'Nahcrichten',
        orderNumber: 'Bestellnummer',

        dietaryTags: 'Ernährungsanforderungen',
        allergenTags: 'Allergen',
        customTags: 'Benutzerdefinierte Tags',
        filterPrice: 'Preis',

        //review order
        orderType: 'Auftragsart',
        customerDetails: 'Deine Details',
        paymentDetails: 'Wählen Sie die Zahlungsmethode',
        checkout: 'KASSE',

        items: 'Bestellungsartikel',
        resetPassword: 'SETZE DEIN PASSWORT ZURÜCK',
        verifyAccount: 'Konto Verifieren',
        orderComplete: 'Bestellung ausgeführt',
        viewOrderDetails: 'Bestell Details ansehen',
        orderReadyTime: 'Ihre Bestellung wird um {{time}} fertig sein ',
        pickUpLocation: 'Abholung bei ',
        deliveringToAddress: 'Lieferung zu ',
        addOrderToFavourites: 'Bestellung zu Favoriten hinzufügen',
        table: 'Tisch ',
        orderDetails: {
          saleId: 'Verkaufs-ID ',
          date: 'Datum ',
          time: 'Zeit ',
          description: 'Beschreibung',
          qty: 'Menge',
          points: 'Points',
          price: 'Pries',
          total: 'Gesamt',
          pointsEarned: 'Punkte Erhalten',
          rewardsEarned: 'Rewards Earned',
          viewInvoice: 'Verdiente Belohnungen',
        },
      },
      leftMenu: {
        buttons: {
          loyaltyMigration: 'Aktualisieren Sie die Loyalität',
        },
      },
      button: {
        continue: 'Weitermachen',
        addToItem: 'Zum Artikel hinzufügen',
        viewCart: 'Warenkorb ansehen',
        clearAll: 'Alles löschen',
        removeSavedAddress: 'Entfernen',
        joinNow: 'Jetzt beitreten',
        continueAsGuest: 'Als Gast fortfahren',
        underage: 'Nein, ich bin minderjährig',
        over18: `Ich bin über 18`,
        mapView: 'Karte',
        listView: 'Liste',
        resendInvoice: 'Rechnung erneut senden',
        purchaseButton: 'Kaufen',
        topupButton: 'Nachfüllen',
        giftCardBalance: 'Kontostand prüfen',
        updateProfile: 'Details aktualisieren',
        updatePassword: 'Einreichen',
        deleteAccount: 'Konto löschen',
        orderOnline: 'Jetzt bestellen',
        claimReceipt: 'Anspruchsbeleg',
        reOrder: 'Neu anordnen',
        updateDetails: 'Details aktualisieren',
        resetFilters: 'Filter zurücksetzen',
        replaceCard: 'Karte Ersetzen',
        changeLanguage: 'Sprache ändern',
        apply: 'Anwenden',
        transactions: 'Loyalitätsgeschichte',
        claimMissingPoints: 'Fehlende Punkte geltend machen',
        pastOrdersMemberButton: 'Frühere Bestellungen',
        favouriteOrders: 'Lieblingsbestellungen',
        giftCard: 'Geschenkkarte',
        profile: 'Profil',
        offers: 'Angebote',
        rewards: 'Belohnung',
        register: 'Melden Sie sich an',
        submit: 'Einreichen',
        editDeliveryEstimate: 'Bearbeiten',
        signIn: 'Einmelden',
        signInWithCode: 'Code bestätigen',
        signUp: 'Einmelden',
        signUpWithCode: 'Code bestätigen',
        signOut: 'Ausmelden',
        modalClose: {
          cancel: 'STORNIEREN',
          dismiss: 'SCHLIESSEN',
        },
        menuSearchCancel: 'Stornieren',
        modalBack: 'ZURÜCK',
        favourites: 'Favourites',
        reorder: 'Neu anordnen',
        pastOrders: 'Frühere Bestellungen',
        headerMemberAbsent: 'Einmelden',
        headerBack: {
          [MENU_SCREEN_ROUTE]: 'MENÜ',
          [INITIAL_SCREEN_ROUTE]: 'ZURÜCK',
        },
        selectLocation: {
          ready: 'FORTFAHREN',
          notReady: 'WÄHLE EINEN ORT',
        },
        purchaseEditor: {
          new: 'In den Warenkorb legen',
          modified: 'Zum Einkaufwagen hinzufügen',
          basePricePrefix: 'Ab ',
        },
        orderSummary: {
          checkout: 'Bezahlen',
          review: 'Bestellung Überprüfen',
        },
        addOffer: {
          offersPresent: 'Angebots- oder Aktionscode anwenden',
          offersAbsent: 'Promocode anwenden',
        },
        orderNote: {
          addOrderNote: 'Bestellnotizen/Besteck hinzufügen',
        },
        stagedPurchase: {
          customise: 'Ändern',
          remove: 'Entfernen',
          unavailable: 'Artikel nicht verfügbar',
        },
        removeOffer: 'Angebot entfernen',
        checkout: 'Bestellung aufgeben',
        removeSavedCard: 'Entfernen',
        startNewOrder: 'Neue Bestellung',
        estimateDelivery: {
          check: 'VERFÜGBARKEIT PRÜFEN',
          checking: 'VERFÜGBARKEIT ÜBERPRÜFEN',
          confirm: 'BESTÄTIG',
          choose: 'ORT WÄHLEN',
        },
        applyGiftCard: 'Anwenden',
        ok: 'OK',
        fallbackToPickup: 'ABHOLUNG MÖGLICH',
        multiPayments: {
          enable: 'Mehrere Verwenden',
          disable: 'Stornieren',
        },
      },
      link: {
        register: {
          prefix: 'Nicht ein Mitglied?',
          action: 'Anmelden',
        },
        forgotPassword: {
          prefix: '',
          action: 'Passwort vergessen?',
        },
        signIn: {
          prefix: 'Schon ein Mitglied?',
          action: 'Einmelden',
        },
        guestSignIn: {
          prefix: '',
          action: 'Als Gest vortsetzen',
        },
      },
      field: {
        signIn: {
          email: 'Email oder Mitglieds Nr.',
          password: 'Passwort',
          mfaCode: 'Verifizierungs Code',
        },
        signUp: {
          email: 'Email',
          Password: 'Passwort',
          mfaCode: 'Verifizierungs Code',
          MemberNo: 'Mitglied Nr',
          GivenNames: 'Vornamen',
          Surname: 'Nachname',
          Mobile: 'Handy, Mobiltelefon',
          DateOfBirth: 'Geburtsdatum',
          Postcode: 'Postleitzahl',
          Suburb: 'Vorort',
          State: 'Zustand',
          Address: 'Adresse',
          StoreID: 'Lieblingsladen',
          VerifyPIN: 'PIN bestätigen',
          Country: 'Land',
          ConfirmEmail: 'E-Mail bestätigen',
          ConfirmPassword: 'Bestätige das Passwort',
          passwordStrengthBarScoreWords: {
            short: 'zu kurz',
            weak: 'schwach',
            okay: 'okay',
            good: 'gut',
            strong: 'stark',
          },
          Sex: 'Geschlecht',
          Phone: 'Telefon',
          Username: 'Nutzername',
          SendEmail: 'Versenden Sie Werbematerial per E-Mail',
          SendSMS: 'Versenden Sie Werbematerial per SMS',
          Terms: 'Ich stimme den AGB zu',
        },
        updateProfile: {
          email: 'Email',
          Password: 'Passwort',
          mfaCode: 'Verifizierungs Code',
          MemberNo: 'Mitglied Nr',
          GivenNames: 'Vornamen',
          Surname: 'Nachname',
          Mobile: 'Handy, Mobiltelefon',
          DateOfBirth: 'Geburtsdatum',
          Postcode: 'Postleitzahl',
          Suburb: 'Vorort',
          State: 'Zustand',
          Address: 'Adresse',
          StoreID: 'Lieblingsladen',
          VerifyPIN: 'PIN bestätigen',
          Country: 'Land',
          ConfirmEmail: 'E-Mail bestätigen',
          ConfirmPassword: 'Bestätige das Passwort',
          Sex: 'Geschlecht',
          Phone: 'Telefon',
          Username: 'Nutzername',
          SendEmail: 'Versenden Sie Werbematerial per E-Mail',
          SendSMS: 'Versenden Sie Werbematerial per SMS',
          Terms: 'Ich stimme den AGB zu',
        },
        loyaltyMigration: {
          memberNo: 'Mitgliedsnummer',
          pointsBalance: 'Aktueller Punktestand',
          lastStoreVisited: 'Zuletzt besuchter Laden',
        },
        claimsReceipt: {
          memberNo: 'Vorherige Mitgliedsnummer',
          pointsBalance: 'Vorheriger Punktestand',
          lastStoreVisited: 'Zuletzt besuchter Laden',
        },
        purchaserDetails: {
          name: 'Name',
          familyName: 'Nachname',
          email: 'Email',
          mobile: 'Handi',
        },
        giftCard: {
          number: 'Geschenkkarte Number',
          pin: 'PIN',
        },
        requiredWarning: 'Erforderlich',
        name: 'Name',
      },
      confirm: {
        signOut: 'Sind sie sicher das Sie ausmelden willen?',
      },
      saleType: {
        [SALE_TYPES.PICKUP]: { name: 'Abholen', subtitle: 'Besuchen Sie uns im Geschäft' },
        [SALE_TYPES.DELIVERY]: { name: 'Abliefern', subtitle: 'Direkt an Ihre Haustür geliefert' },
        [SALE_TYPES.DINE_IN]: { name: 'Zum Tisch bestellen', subtitle: 'Speisen Sie bei uns' },
        [SALE_TYPES.TABLE_ORDER]: { name: 'Tischbestellung', subtitle: 'Speisen Sie bei uns' },
        [SALE_TYPES.TAKE_AWAY]: { name: 'Wegnehmen', subtitle: 'Nehmen Sie Ihre Bestellung mit' },
        [SALE_TYPES.CATERING]: {
          name: 'Versorgung',
          subtitle: 'Bestellen Sie für eine große Gruppe',
        },
        [SALE_TYPES.WEB_ORDERING]: {
          name: 'Onlinebestellung',
          subtitle: 'Besuchen Sie uns im Geschäft',
        },
      },
      transactionsDescriptionMap: {
        1: 'Verkauf',
        2: 'Punkte einlösen',
        3: 'Aufladen',
        4: 'Geld einlösen',
        5: 'Punkte erhöhen',
        6: 'Punkte verringern',
        7: 'Geld erhöhen',
        8: 'Geld verringern',
        9: 'Verkaufsbelohnung',
        10: 'Belohnung einlösen',
        66: 'Geburtstagsgeschenk',
      },
      choiceSet: {
        minMobile: 'MIN',
        maxMobile: 'MAX',
        freeMobile: 'GRATIS',
        minDesktop: 'MINIMUM',
        maxDesktop: 'MAXIMUM',
        freeDesktop: 'GRATIS',

        quantityHint: {
          constant: 'Bestelle {{quantity}}',
          range: 'Bestell zwischen {{min}}-{{max}}',
          maximum: 'Bestelle bis zu {{max}}',
          freeSuffix: '({{free}} gratis)',
        },
      },
      totals: {
        total: 'Menge',
        discount: 'Rabat',
        pointsPrice: 'Punktenkoste',
        pointsAward: 'Punkte Verdient',
        rewardsAward: 'Belonungen Verdient',
        giftCardBalance: 'Guthaben vorhanden',
      },
      paymentMethod: {
        [PAYMENT_METHODS.CREDIT_CARD]: 'Kreditkarte',
        [PAYMENT_METHODS.GIFT_CARD]: 'Geschenkkarte',
        [PAYMENT_METHODS.MEMBER_MONEY]: 'Mitgliedsgeld',
        [PAYMENT_METHODS.MEMBER_POINTS]: 'Mitgliedgeld',
        [PAYMENT_METHODS.MEMBER_REWARDS]: 'Mitgliedbelonungen',
        [PAYMENT_METHODS.SAVED_CARD]: 'Gespeicherte Karte',
        [PAYMENT_METHODS.APPLE_PAY]: 'Apple Pay',
        [PAYMENT_METHODS.GOOGLE_PAY]: 'Google Pay',
        [PAYMENT_METHODS.PAY_LATER]: {
          [SALE_TYPES.DINE_IN]: 'Tischservice',
          [SALE_TYPES.TABLE_ORDER]: 'Tischservice',
          [SALE_TYPES.PICKUP]: 'Bezahlung bei Abholung',
          [SALE_TYPES.TAKE_AWAY]: 'Bezahlung bei Abholung',
          [SALE_TYPES.DRIVE_THRU]: 'Bezahlung bei Abholung',
          [SALE_TYPES.WEB_ORDERING]: 'Bezahlung bei Abholung',
          [SALE_TYPES.DELIVERY]: 'Bezahlung bei Ablieferung',
        },
      },
      statusSteps: {
        [ORDER_SUBMITTED]: {
          incomplete: 'Bestellung aufgeben',
          complete: 'Bestellung aufgegeben',
        },
        [RECEIVED_AT_STORE]: {
          incomplete: 'Empfang im Geschäft',
          complete: 'Im Geschäft erhalten',
        },
        [PROCESSED_AT_STORE]: {
          incomplete: 'Verarbeitung im Store',
          complete: 'Bestellung vorbereiten',
        },
        [READY_TO_COLLECT]: {
          incomplete: 'Vorbereitungsauftrag',
          complete: {
            [SALE_TYPES.PICKUP]: 'Abholbereit',
            [SALE_TYPES.WEB_ORDERING]: 'Bereit zum Abholen',
            [SALE_TYPES.DELIVERY]: 'Bestellung mit Fahrer', // NOTE: only for client managed delivery
            [SALE_TYPES.DINE_IN]: 'Vertig',
            [SALE_TYPES.TAKE_AWAY]: 'Abholbereit',
          },
        },
        [PICKED_UP_BY_DRIVER]: {
          incomplete: 'Bereit für den Fahrer abzuholen',
          complete: 'Vom Fahrer abgeholt',
        },
        [DELIVERED]: {
          incomplete: 'Bestellung im Transit',
          complete: 'Bestellung abgeliefert',
        },
      },
      paymentMethodUnavailableReason: {
        [PAYMENT_METHOD_UNAVAILABLE_REASON.NOT_ENABLED]: 'Nicht aktiviert',
        [PAYMENT_METHOD_UNAVAILABLE_REASON.INSUFFICIENT_BALANCE]: 'Unzureichendes Guthaben',
        [PAYMENT_METHOD_UNAVAILABLE_REASON.MEMBER_MISSING]: 'Bitte einmelden zu verwenden',
        [PAYMENT_METHOD_UNAVAILABLE_REASON.MEMBER_INVALID]: 'Bitte verifieren zu verwenden',
        [PAYMENT_METHOD_UNAVAILABLE_REASON.SALE_TYPE_NOT_COMPATIBLE]:
          'Nicht verfügbar mit Bestelltyp',
        [PAYMENT_METHOD_UNAVAILABLE_REASON.OFFER_NOT_COMPATIBLE]: 'Im Angebot nicht verfügbar',
        [PAYMENT_METHOD_UNAVAILABLE_REASON.CART_NOT_COMPATIBLE]:
          'Einige Artikel im Warenkorb können nicht eingelöst werden',
        [PAYMENT_METHOD_UNAVAILABLE_REASON.GIFT_CARD_INACTIVE]: 'Karte nicht aktiviert',
        // [PAYMENT_METHOD_UNAVAILABLE_REASON.TOTAL_INVALID]: '',
        [PAYMENT_METHOD_UNAVAILABLE_REASON.DELIVERY_PROVIDER_NOT_COMPATIBLE]:
          'Bei diesem Lieferanbieter nicht verfügbar',
      },
      purchaserDetailInvalid: {
        name: 'Ungültig',
        familyName: 'Ungültig',
        mobile: 'Ungültig',
        email: 'Ungültig',
      },
      timeSummary: {
        asap: 'SCHNELLSTMÖGLICH',
        scheduleForLater: 'Planen Sie für später',
        selectPickupTime: 'Wählen Sie die Abholzeit',
      },
      completedOrderMessage: {
        [SALE_TYPES.PICKUP]: '',
        [SALE_TYPES.DELIVERY]: '',
        [SALE_TYPES.DINE_IN]: '',
        [SALE_TYPES.TAKE_AWAY]: '',
        [SALE_TYPES.CATERING]: 'Bestellung bestätigt',
        [SALE_TYPES.TABLE_ORDER]: '',
      },
      applyPromoCode: {
        notFound: 'Aktion nicht gefunden',
        failure: 'Problem beim Anwenden des Aktionscodes',
        itemAdded: 'Aktionsartikel zum Warenkorb hinzugefügt',
        success: 'Aktion angewended',
        placeholder: 'Promo-Code hinzufügen',
        coupons: 'Gutscheine',
      },
      selectOfferFailed: {
        unavailable: 'Angebot nicht verfügbar',
        item: 'Angebotsartikel in diesem Ort nicht verfügbar',
        default: 'Angebot konnte nicht ausgewählt werden',
      },
      failureReason: {
        [FAILURE_REASON.FETCH_FAILED]: 'Es kann keine Verbindung zum Server hergestellt werden',
        [FAILURE_REASON.LOCATION_OFFLINE]: 'Der Standort ist offline',
        [FAILURE_REASON.LOCATION_CLOSED]: 'Standort geschlossen',
        [FAILURE_REASON.UNKNOWN]: 'Problem mit der Bestellung',
      },
      stockBalanceThreshold: {
        [STOCK_BALANCE_THRESHOLDS.STOCK_BALANCE_THRESHOLD_0]: 'AUSVERKAUFT',
        [STOCK_BALANCE_THRESHOLDS.STOCK_BALANCE_THRESHOLD_1]: 'FAST AUSVERKAUFT',
        // TODO: Confirm default strings for last 3 thresholds
        [STOCK_BALANCE_THRESHOLDS.STOCK_BALANCE_THRESHOLD_2]: '',
        [STOCK_BALANCE_THRESHOLDS.STOCK_BALANCE_THRESHOLD_3]: '',
        [STOCK_BALANCE_THRESHOLDS.STOCK_BALANCE_THRESHOLD_4]: '',
      },
      memberVerificationRequirment:
        'Um eine Bestellung aufgeben zu können, müssen Sie ein verifiziertes Mitglied sein',
      guestOrderMessageBeginning: 'Du musst',
      signInLink: ' Anmelden ',
      guestOrderMessageConjuction: 'oder',
      registerLink: ' Registrieren ',
      guestOrderMessageEnding: 'eine Bestellung aufgeben',
      payWithMultiplePayment: 'Bezahlen Sie mit mehreren Zahlungsmethoden',
      itemOutOfStockMessage: 'Dieser Artikel ist nicht mehr verfügbar',
      rememberCard: 'karte merken',
      doNotRememberCard: 'ich erinnere mich nicht an die Karte',
      rewardsAvailable: 'Verfügbar: ',
      // itemOutOfStockAlertMessage: 'Only {{itemBalance}} of this item is available',
      itemQuantityWarningMessage: 'Nur {{newItemQuantity}} x {{someLabel}} ubrig',
      stagedChoiceSetItemQuantityWarningMessage:
        'Sie haben alle verbleibenden Mengen dieses Artikels hinzugefügt',
      energy: {
        kilojouleSuffix: 'kJ',
        calorieSuffix: 'kcal',
      },
      accountPage: {
        stampcard: {
          progressCounter: '{{earned}}/{{max}}',
        },
        buttons: {
          topUp: 'Guthaben aufladen',
          scanCard: 'Karte scannen',
        },
        creditLoyalty: {
          progressMessage:
            'Sie sind noch {{points}} von einem Mitgliedsgeld in Höhe von 10 $ entfernt',
        },
      },
      loyaltyMigration: {
        landingPage: {
          subtitle:
            'Bitte geben Sie Ihre aktuellen Kontodaten an, damit wir mit der Aktualisierung Ihrer Mitgliedschaft fortfahren können',
          submitButton: 'Konto überprüfen',
          loginPretext: 'Bereits ein aktualisiertes Mitglied?',
          loginText: 'Anmeldung',
        },
        detailsPage: {
          subtitle: 'Bitte teilen Sie uns einige zusätzliche Informationen mit',
          submitButton: 'Details aktualisieren',
        },
        signInPage: {
          subtitle:
            'Vielen Dank für die Aktualisierung Ihrer Kontodaten. Wir senden Ihnen in Kürze eine E-Mail, in der Sie Ihr Konto bestätigen können.',
        },
        claimPointsPage: {
          subtitle:
            'Geben Sie unten die Daten Ihrer vorherigen Mitgliedschaft ein, um Punkte zu erhalten',
          submitButton: 'Fordern Sie eine Mitgliedschaft an',
        },
        alreadyLoggedInMessage: 'Du bist bereits eingeloggt.',
      },
      dateTimeLabel: 'Datum & Uhrzeit',
      orderTotalPaymentSummaryLabel: 'Verbleibend',
      buttons: {
        resendVerification: 'Verifizierung erneut senden',
        home: 'Startseite',
      },
      snackbarMessages: {
        verifyPinRequired: 'Verifizierungs-PIN erforderlich',
        success: 'Erfolg',
        pointsClaimed: 'Mitgliedschaftspunkte erfolgreich eingelöst.',
        cannotCompleteVerification: 'Verifizierung konnte nicht abgeschlossen werden.',
        accountDeleted: 'Konto gelöscht! Abgemeldet...',
        accountVerified: 'Ihr Konto wurde nun verifiziert...',
        deliveryTimeUpdate: 'Lieferzeit aktualisiert, um den Warenkorbwert widerzuspiegeln',
      },
      deliveryTimeUpdateMessage:
        'Ihre geschätzte Lieferzeit wurde aktualisiert. Möchten Sie fortfahren?',
      zxcvbn: {
        'Straight rows of keys are easy to guess': 'Gerade Tastenreihen sind leicht zu erraten',
        'Short keyboard patterns are easy to guess': 'Kurze Tastaturmuster sind leicht zu erraten',
        'Use a longer keyboard pattern with more turns':
          'Verwenden Sie ein längeres Tastaturmuster mit mehr Drehungen',
        'Repeats like "aaa" are easy to guess': 'Wiederholungen wie "aaa" sind leicht zu erraten',
        'Repeats like "abcabcabc" are only slightly harder to guess than "abc"':
          'Wiederholungen wie "abcabcabc" sind nur etwas schwieriger zu erraten als "abc"',
        'Sequences like abc or 6543 are easy to guess':
          'Sequenzen wie abc oder 6543 sind leicht zu erraten',
        'Recent years are easy to guess': 'Aktuelle Jahre sind leicht zu erraten',
        'Dates are often easy to guess': 'Daten sind oft leicht zu erraten',
        'This is a top-10 common password':
          'Dies ist ein häufig verwendetes Passwort unter den Top 10',
        'This is a top-100 common password':
          'Dies ist ein häufig verwendetes Passwort unter den Top 100',
        'This is a very common password': 'Dies ist ein sehr häufig verwendetes Passwort',
        'This is similar to a commonly used password':
          'Dies ähnelt einem häufig verwendeten Passwort',
        'A word by itself is easy to guess': 'Ein Wort für sich allein ist leicht zu erraten',
        'Names and surnames by themselves are easy to guess':
          'Namen und Nachnamen für sich allein sind leicht zu erraten',
        'Common names and surnames are easy to guess':
          'Gängige Namen und Nachnamen sind leicht zu erraten',
      },
    },
  },
  en: {
    translation: {
      languages: {
        en: 'English',
        jp: '日本語',
        es: 'Español',
      },
      thanksForSignUp: 'Thanks for signing up',  //todo convert for global strings
      checkEmail: 'Please check your email',     //todo convert for global strings
      menuAvailablity: 'This menu is available {{menuAvailablity}}',
      itemAvailable: 'Available: {{itemAvailable}}',
      showItemDetails: 'Show details',
      dailyIntake: {
        // it's a bit fuzzy whether these are correct, they say 2000-2500 kcal for men/women but it's all a recommended average anyway
        kilojoules: 'The average adult daily energy intake is 8700kJ',
        kilojoulesAndCalories: 'The average adult daily energy intake is 8700kJ or 2100kcal',
        calories: 'The average adult daily energy intake is 2100kcal',
      },
      joinLoyaltyProgram: 'Join the loyalty program',
      joinLoyaltyProgramMessage: 'Become a member and get exclusive rewards and benefits',
      joinLoyaltyCallMessage:
        "You could have earned {{pointsEarned}} {{pointsName}} from this transaction. Join now and we'll send you member-exclusive benefits and rewards.",
      registration: {
        verificationReminderModal: {
          title: 'Thanks for joining the loyalty program',
          message: "We'll be sending you an email shortly where you can verify your account.",
        },
      },
      favourite: {
        createNewFavouriteModal: {
          title: 'Create New Favourite',
          message: 'Do you want to name this favourite? A name is optional.',
          button: 'Create',
        },
      },
      itemCartWarningTitle: 'Cart Items may have changed', //todo convert for global strings
      itemCartWarningText: "Some Items won't be available at this locations", //todo convert for global strings
      proofOfAgeTitle: 'Are you over 18?',
      itemHasAlcohol: 'Proof of age by a valid ID may be required to receive this item',
      checkoutItemHasAlcohol: 'Proof of age by a valid ID may be required to receive these items',
      proofOfAgeMessage:
        'Please confirm that you are over 18 years of age. Proof of age may be required to receive this item.',
      cvcHelper: 'Please fill in all fields to show CVC',
      tieredLoyaltyPointsToThreshold: 'Spend {{currency}}{{points}} to achieve {{tierName}} Perks',
      maxValueWarning: 'Please be aware we have an Online Ordering limit of {{amount}}',
      myGiftCardDetails: 'My Details',
      deliveryDetails: 'Delivery Details',
      recipientDetails: `Recipient's Details`,
      giftCardTopUpCustomValue: 'Nominate Value',
      buyForMyself: 'Buy for myself',
      buyAsGift: 'Buy as a gift',
      memberMoneyHeader: 'Member Money',
      giftCardMemberBalance: 'Current Balance: {{currency}}{{balance}}',
      addLoyaltyWallet: 'Add To Loyalty Wallet',
      giftCardTopUp: 'Top Up',
      giftCardHeader: 'Gift Card',
      checkBalance: 'Check Balance',
      purchaseGiftCard: 'Purchase Gift Card',
      giftCarHeader: 'Gift Card',
      updateSuccess: 'Your details have been successfully updated.',
      profileDetails: 'Personal Details',
      profileHeader: 'Profile',
      updatePassword: 'Update Password', //todo convert for global strings
      updatePasswordDetails: 'Update your password',
      currentPassword: 'Current Password', //todo convert for global strings
      changePassword: 'Change Password', //todo convert for global strings
      confirmPassword: 'Confirm Password', //todo convert for global strings
      newPassword: 'New Password', //todo convert for global strings
      deleteAccount: 'Delete Account', //todo convert for global strings
      deleteAccountDetails:
        'Are you sure? If you continue all profile information will be removed and purchases history will not be accessible',
      loyaltyRewardsPointsAway:
        'You are {{remainingPoints}} points away from {{currency}}10 member money',
      offersHeader: 'Offers',
      claimReceiptSuccess: `You've successfully claimed this receipt.`,
      claimMissingPointsInfo: 'Enter details below to claim unassigned points',
      claimMissingPointsHeader: 'CLAIM MISSING POINTS',
      transactionsHeader: 'LOYALTY HISTORY',
      replaceCardSuccess: 'Your details have been updated.',
      replaceCardInfo:
        'If you have misplaced your card you will need to  get a replacement instore. To transfer your points onto your new card enter the details below.',
      changeLanguageInfo: 'Please select the language of your preference',
      allKey: 'All',
      replaceCardHeader: 'Replace Card',
      changeLanguageHeader: 'Change Language',
      favouriteOrdersHeader: 'Favourite Orders',
      pastOrdersHeader: 'Past Orders',
      loyaltyWallet: 'ADD TO LOYALTY WALLET',
      memberPoints: 'Member Points',
      memberMoney: 'Member Money',
      resetYourPasswordMessage:
        'An email has been sent to your email address containing instructions to reset your password.',
      resetPasswordSubtitle: 'To set your new password, please enter and confirm it below.',
      memberMoneyTitle: 'Take a look at your current balance for member points and member money',
      memberPointsTitle: 'Take a look at your current balance for member points',
      loyaltyGreeting: 'Welcome',
      termsAndContition: 'Terms And Conditions',
      termsPrefix: 'I agree to',
      moreInformation: 'More Information',
      message: {
        maxCharactersEnd: ' characters',
        maxCharactersStart: 'Maximum ',
        minCharactersEnd: ' characters',
        minCharactersStart: 'Minimum ',
        savedAddressError: 'Unknown error occured',
        savedAddressRemoved: 'Success',
      },
      form: {
        areRequired: 'are',
        isRequired: 'is',
        required: 'Required',
        requiredField: 'required',
        mobileValidation: 'Invalid mobile number',
        termsValidation: 'You must agree to the terms and conditions',
        validatePhoneNumbers: 'Only the digits 0-9 are valid',
        emailValidation: 'Invalid email address',
        memberNumberValidation: 'Invalid MemberNo',
        invalidNumber: 'Invalid number',
        scheduleGiftcard: 'Date and time must be after now',
        passwordTooShort: 'Too Short',
        valuesMismatch: 'Values do not match',
        passwordNotSecure: 'Password not secure enough',
        onlyNumbersRequired: 'Number required',
      },
      memberNameFallback: 'Profile',
      loyaltyMoneyNameFallback: 'Member Money:',
      loyaltyPointsNameFallback: 'Points',
      surchargeNameFallback: 'Surcharge',
      tableNumber: 'Table Number',
      table: 'Table',

      fraudPreventionMessage: {
        collection: 'We reserve the right to ask for ID upon collection of online orders',
        delivery: 'We reserve the right to ask for ID upon delivery of online orders',
      },
      emptyCartMessage: 'Your cart is empty',
      removeFiltersWarnings: 'Try removing some filters',
      noResultsInMenuSearchMessage: `We couldn't find any matches`,
      noLocationsMessage: 'No matching locations currently taking orders',
      locationNotTakingOrders: 'Currently not taking orders',
      locationOpensAt: 'Closed, opens at ',
      noKnownUserLocationMessage:
        'Please ensure you have selected the store you want to order from, location services unavailable',
      minimumOrderWithCouponMessage:
        'Spend {{currency}}{{amount}} or more to meet coupon minimum spend requirement',
      minimumOrderWarningMessage: 'Minimum order amount not met',
      minimumExcludesSurchargeMessage: '*Excludes delivery surcharges',
      invalidStagedPurchasePresentMessage: 'Invalid item in cart',
      incompatibleOfferMessage:
        'Offer not compatible with cart. Please remove offer or add compatible item',
      chargedMessage: {
        maybe: 'You may have been charged, please contact the store to resolve this issue.',
        yes: 'You have been charged, please contact the store to resolve this issue.',
        no: 'You have not been charged.',
      },
      problemUpdatingKeyOrderProperty: 'Something went wrong. Please try again.',
      problemFinalisingOrderMessage: 'Problem finalising order.',
      problemWithReCaptchaMessage: 'There was a problem with your reCAPTCHA attempt',
      problemFetchingGiftCardMessage:
        'Problem looking up gift card balance\nPlease check Number and PIN',
      successFetchingGiftCardMessage: 'Gift Card found',
      badGiftCardMessage: 'Gift Card PIN does not match',
      paymentFailedMessage: 'There was a problem with your payment',
      noValidTimeMessage: 'Please try another location or order type',
      asap: 'ASAP',
      noValidTime: '⚠️ Unavailable',
      fallbackToPickupMessage: "Don't see what you're looking for?",
      invalidTime: '⚠️ Invalid',
      flattenedDeliverySurcharge: 'Delivery Fee',
      offerTypeDivider: 'OR',
      stagedPurchaseDiscount: 'Discount Included',
      checkoutSignInPrompt: 'SIGN IN',
      checkoutSignInPromptReason: {
        guestOrderingDisabled: 'Please sign in to place your order',
        guestOrderingEnabled: 'To earn and redeem rewards',
      },
      maximumOrderAmountLabel: 'MAXIMUM',
      minimumOrderAmountLabel: 'MINIMUM',
      brandsEducationMessage:
        'We have multiple brands available from this location, use the brand menu bar to change between them and view what is available. You can add items from any of these brands into your cart.',
      preSaleMessage: {
        title: {
          [SALE_TYPES.PICKUP]: '',
          [SALE_TYPES.DELIVERY]: '',
          [SALE_TYPES.DINE_IN]: '',
          [SALE_TYPES.TAKE_AWAY]: '',
          [SALE_TYPES.CATERING]: '',
          [SALE_TYPES.TABLE_ORDER]: '',
        },
        message: {
          [SALE_TYPES.PICKUP]: '',
          [SALE_TYPES.DELIVERY]: '',
          [SALE_TYPES.DINE_IN]: '',
          [SALE_TYPES.TAKE_AWAY]: '',
          [SALE_TYPES.CATERING]: '',
          [SALE_TYPES.TABLE_ORDER]: '',
        },
      },
      unavailablePurchasesWarning: {
        title: '',
        text: 'Some items in your cart are unavailable. Please view your cart for more information.',
      },
      mfaHelperText: 'Please check your SMS or email for the code',
      deliveryEstimateFailedMessage: 'A problem has occurred ({{reason}})',
      saveCardLabel: 'Remember',
      allBrandsLabel: 'All',
      deliveryTrackingLink: 'Track Your Order',
      locationSearchResult: '{{count}} Location',
      locationSearchResult_plural: '{{count}} Locations',
      eta: 'Expected',
      editDeliveryDetails: 'Edit Details',
      deliveryTimeMayVaryMessage:
        'Please note that estimated delivery time may vary depending on your order value',
      creditCardMinimumNotMetMessage: 'Credit card minimum amount not met',
      offersIfNotVerified: 'Offers only available for verified members',
      profileVerificationText: 'Your profile is not verified. Please verify!',
      deleteAccountIfNotVerified: 'Only verified members can delete their accounts',
      favouriteAccountIfNotVerified: 'Favourite orders are only available for verified members',
      claimReceiptsIfNotVerified:
        'You will be able to claim your receipt once you have verified your account!',
      purchaseStaged: {
        new: 'Added to order',
        modified: 'Saved to order',
      },
      paymentOption: {
        now: 'Send Now',
        later: 'Send Later',
      },
      placeholder: {
        firstName: 'First Name',
        lastName: 'Last Name',
        email: 'Email',
        confirmEmail: 'Confirm Email',
        message: 'Message',
        giftcardAmount: 'Amount',
        verifyPin: 'Verify PIN',
        receiptNo: 'Sale ID',
        saleTotal: 'Sale Total',
        transactionTime: 'Purchase Time',
        transactionDate: 'Purchase Date',
        storeID: 'Location',
        loyaltyDatePicker: 'Purchase Date',
        verificationCode: 'Verification Code',
        memberNo: 'Member Number',
        giftCardNo: 'Gift Card Number',
        locationSearch: 'Search',
        menuSearch: 'Search this menu',
        promoCode: 'PROMOCODE',
        memberOffers: 'NO OFFER',
        tableNumber: 'REQUIRED',
        orderNotes: 'Any special instructions for us?',
        deliveryNotes: 'Any notes for your driver?',
      },
      title: {
        asapSubTitle: 'Estimated 30-40 mins', //todo convert for global strings
        deliveryAsapSubTitle: 'Estimated 30 - 40 mins', //todo convert for global strings
        pickupAsapSubTitle: 'Estimated time: {{waitTime}} mins', //todo convert for global strings
        createNewFavourite: 'Create New Favourite',
        loyaltyPointsEarned: 'Points Earned',
        loyaltyTotal: 'Total',
        loyaltyPrice: 'Price',
        loyaltyPoints: 'Points',
        loyaltyQuantity: 'Qty',
        loyaltyDescriptionHeader: 'Description',
        filterSaleType: 'Sale Type',
        loyaltyDateString: 'Date',
        loyaltyTimeString: 'Time',
        filter: 'Filters',
        pastOrdersSaleID: 'Sale ID',
        modal: {
          signIn: 'Sign In',
          resetPassword: 'Reset Your Password',
          offer: {
            offerPresent: 'Selected Offer',
            offerAbsentOffersPresent: 'Apply Offer',
            offerAbsentOffersAbsent: 'Enter Code',
            offerAutomatic: 'Offer has been applied automatically',
            offerEnforced: "Offer has been applied automatically and can't be removed",
          },
          brandsEducation: 'Did you know?',
          memberRegister: 'Loyalty Membership System Registration',
          upsellTitle: 'Complete your meal with',
        },
        newOrder: 'New Order',
        forYou: 'For You',
        sizeChoice: 'Size',
        saleType: 'Order Type',
        location: 'Location',
        cart: 'Cart',
        offer: 'Offer',
        notes: 'Order Notes',
        deliveryNotes: 'Driver Notes',
        pickupTime: 'Pickup Time',
        memberBalances: 'Current Balance',
        memberBalances_plural: 'Current Balances',
        paymentMethod: 'Payment Methods',
        purchaserDetails: 'Your Details',
        deliveryAddress: 'Your Address',
        deliveryTime: {
          heading: 'Delivery Time',
          desired: 'Desired Delivery Time',
          estimated: 'Estimated Delivery Time',
        },
        messages: 'Messages',
        orderNumber: 'Order Number',

        //filter modal
        dietaryTags: 'Dietary Requirements',
        allergenTags: 'Allergen',
        customTags: 'Custom Tags',
        filterPrice: 'Price',

        //review order
        checkout: 'CHECKOUT',
        orderType: 'Order Type',
        customerDetails: 'Your Details',
        paymentDetails: 'Select Payment Method',

        items: 'Items', //todo convert for global strings
        resetPassword: 'RESET YOUR PASSWORD',
        verifyAccount: 'Verify Account',
        orderComplete: 'Order Complete',
        viewOrderDetails: 'View order details',
        orderReadyTime: 'Your order will be ready at ',
        pickUpLocation: 'Pick Up from ',
        deliveringToAddress: 'Delivering to ',
        addOrderToFavourites: 'Add order to favourites',
        table: 'Table ',
        orderDetails: {
          saleId: 'Sale ID ',
          date: 'Date ',
          time: 'Time ',
          description: 'Description',
          qty: 'Qty',
          points: 'Points',
          price: 'Price',
          total: 'Total',
          pointsEarned: 'Points Earned',
          rewardsEarned: 'Rewards Earned',
          viewInvoice: 'View Invoice',
        },
      },
      leftMenu: {
        buttons: {
          loyaltyMigration: 'Update Loyalty',
        },
      },
      button: {
        continue: 'Continue',
        addToItem: 'Add to Item',
        viewCart: 'View Cart',
        continueCheckout: 'Continue To Checkout',
        clearAll: 'Clear all',
        removeSavedAddress: 'Remove',
        joinNow: 'Join Now',
        continueAsGuest: 'Continue as Guest',
        underage: 'No, I am underage',
        over18: `I am over 18`,
        mapView: 'Map',
        listView: 'List',
        resendInvoice: 'Resend Invoice',
        purchaseButton: 'Purchase',
        topupButton: 'Top Up',
        giftCardBalance: 'Check Balance',
        updateProfile: 'Update Details',
        updatePassword: 'Update Password',
        deleteAccount: 'Delete Account',
        orderOnline: 'Order Now',
        claimReceipt: 'Claim Receipt',
        reOrder: 'Reorder',
        updateDetails: 'Update Details',
        resetFilters: 'Reset Filters',
        replaceCard: 'Replace Card',
        changeLanguage: 'Change Language',
        apply: 'Apply',
        transactions: 'Loyalty History',
        claimMissingPoints: 'Claim missing points',
        claimPreviousPoints: 'Claim Previous Points',
        pastOrdersMemberButton: 'Past Orders',
        favouriteOrders: 'Favourite Orders',
        giftCard: 'Gift Card',
        profile: 'Profile',
        offers: 'Offers',
        rewards: 'Rewards',
        register: 'Sign Up',
        submit: 'Submit',
        editDeliveryEstimate: 'Edit',
        signIn: 'Sign In',
        signInWithCode: 'Confirm Code',
        signUp: 'Sign In',
        signUpWithCode: 'Confirm Code',
        signOut: 'Sign Out',
        modalClose: {
          cancel: 'CANCEL',
          dismiss: 'CLOSE',
        },
        menuSearchCancel: 'Cancel',
        modalBack: 'BACK',
        favourites: 'Favourites',
        reorder: 'Reorder',
        pastOrders: 'Past Orders',
        headerMemberAbsent: 'Sign In',
        headerBack: {
          [MENU_SCREEN_ROUTE]: 'MENU',
          [INITIAL_SCREEN_ROUTE]: 'BACK',
        },
        selectLocation: {
          ready: 'PROCEED',
          notReady: 'SELECT A LOCATION',
        },
        purchaseEditor: {
          new: 'Add To Cart',
          modified: 'Save To Cart',
          basePricePrefix: 'From ',
          next: 'Next', //todo convert for global strings
        },
        orderSummary: {
          checkout: 'Checkout',
          review: 'Review Order',
        },
        addOffer: {
          offersPresent: 'Apply Offer or Promocode',
          offersAbsent: 'Apply Promocode',
        },
        orderNote: {
          addOrderNote: 'Add order notes/cutlery',
        },
        stagedPurchase: {
          customise: 'Customise',
          remove: 'Remove',
          unavailable: 'Item Unavailable',
        },
        removeOffer: 'REMOVE OFFER',
        checkout: 'Place Order',
        removeSavedCard: 'Remove',
        startNewOrder: 'New Order',
        estimateDelivery: {
          check: 'CHECK AVAILABILITY',
          checking: 'CHECKING AVAILABILITY',
          confirm: 'CONFIRM',
          choose: 'CHOOSE LOCATION',
        },
        applyGiftCard: 'Apply',
        ok: 'OK',
        fallbackToPickup: 'PICK UP AVAILABLE',
        multiPayments: {
          enable: 'Use Multiple',
          disable: 'Cancel',
        },
      },
      link: {
        register: {
          prefix: 'Not a member?',
          action: 'Sign Up',
        },
        forgotPassword: {
          prefix: '',
          action: 'Forgot Password?',
        },
        signIn: {
          prefix: 'Already a member?',
          action: 'Sign In',
        },
        guestSignIn: {
          prefix: '',
          action: 'Continue as Guest',
        },
      },
      field: {
        signIn: {
          email: 'Email or Member No.',
          password: 'Password',
          mfaCode: 'Verification Code',
        },
        signUp: {
          Email: 'Email',
          password: 'Password',
          MemberNo: 'Member No',
          GivenNames: 'Given Names',
          Surname: 'Surname',
          Mobile: 'Mobile',
          DateOfBirth: 'Date of Birth',
          Postcode: 'Postcode',
          mfaCode: 'Verification Code',
          Suburb: 'Suburb',
          State: 'State',
          Address: 'Address',
          StoreID: 'Favourite Store',
          VerifyPIN: 'Verify Pin',
          Country: 'Country',
          ConfirmEmail: 'Confirm Email',
          ConfirmPassword: 'Confirm Password',
          passwordStrengthBarScoreWords: {
            short: 'too short',
            weak: 'weak',
            okay: 'okay',
            good: 'good',
            strong: 'strong',
          },
          Sex: 'Gender',
          Phone: 'Phone',
          Username: 'Username',
          SendEmail: 'Send Promotional Material via Email',
          SendSMS: 'Send Promotional Material via SMS',
          Terms: 'I agree to the terms and conditions',
        },
        updateProfile: {
          Email: 'Email',
          password: 'Password',
          MemberNo: 'Member No',
          GivenNames: 'Given Names',
          Surname: 'Surname',
          Mobile: 'Mobile',
          DateOfBirth: 'Date of Birth',
          Postcode: 'Postcode',
          mfaCode: 'Verification Code',
          Suburb: 'Suburb',
          State: 'State',
          Address: 'Address',
          StoreID: 'Favourite Store',
          VerifyPIN: 'Verify Pin',
          Country: 'Country',
          ConfirmEmail: 'Confirm Email',
          ConfirmPassword: 'Confirm Password',
          Sex: 'Gender',
          Phone: 'Phone',
          Username: 'Username',
          SendEmail: 'Send Promotional Material via Email',
          SendSMS: 'Send Promotional Material via SMS',
          Terms: 'I agree to the terms and conditions',
        },
        loyaltyMigration: {
          memberNo: 'Member Number',
          pointsBalance: 'Current Points Balance',
          lastStoreVisited: 'Last Store Visited',
        },
        claimsReceipt: {
          memberNo: 'Previous Member Number',
          pointsBalance: 'Previous Points Balance',
          lastStoreVisited: 'Last Store Visited',
        },
        purchaserDetails: {
          name: 'Name',
          familyName: 'Family Name',
          mobile: 'Mobile', //todo convert for global strings
          email: 'Email',
        },
        giftCard: {
          number: 'Gift Card Number',
          pin: 'PIN',
        },
        requiredWarning: 'Required',
        name: 'Name',
      },
      confirm: {
        signOut: 'Are you sure you want to sign out?',
      },
      saleType: {
        [SALE_TYPES.PICKUP]: { name: 'Pick Up', subtitle: 'Visit us in store' },
        [SALE_TYPES.DELIVERY]: { name: 'Delivery', subtitle: 'Delivered straight to your door' },
        [SALE_TYPES.DINE_IN]: { name: 'Order to Table', subtitle: 'Dine in with us' },
        [SALE_TYPES.TABLE_ORDER]: { name: 'Table Order', subtitle: 'Dine in with us' },
        [SALE_TYPES.TAKE_AWAY]: { name: 'Take Away', subtitle: 'Take away your order' },
        [SALE_TYPES.CATERING]: { name: 'Catering', subtitle: 'Order for a large group' },
        [SALE_TYPES.WEB_ORDERING]: { name: 'Web Order', subtitle: 'Visit us in store' },
      },
      transactionsDescriptionMap: {
        1: 'Sale',
        2: 'Redeem Points',
        3: 'Top Up',
        4: 'Redeem Money',
        5: 'Points Increase',
        6: 'Points Decrease',
        7: 'Money Increase',
        8: 'Money Decrease',
        9: 'Sale Reward',
        10: 'Redeem Reward',
        66: 'Birthday Gift',
      },
      choiceSet: {
        minMobile: 'MIN',
        maxMobile: 'MAX',
        freeMobile: 'FREE',
        minDesktop: 'MINIMUM',
        maxDesktop: 'MAXIMUM',
        freeDesktop: 'FREE',

        quantityHint: {
          constant: 'Choose {{quantity}}',
          range: 'Choose {{min}}-{{max}}',
          maximum: 'Choose up to {{max}}',
          freeSuffix: '({{free}} free)',
        },
      },
      totals: {
        total: 'Total',
        discount: 'Discount',
        pointsPrice: 'Points Cost',
        pointsAward: 'Points Earned',
        rewardsAward: 'Rewards Earned',
        giftCardBalance: 'Balance Available',
      },
      paymentMethod: {
        [PAYMENT_METHODS.CREDIT_CARD]: 'Credit Card',
        [PAYMENT_METHODS.GIFT_CARD]: 'Gift Card',
        [PAYMENT_METHODS.MEMBER_MONEY]: 'Member Cash',
        [PAYMENT_METHODS.MEMBER_POINTS]: 'Member Points',
        [PAYMENT_METHODS.MEMBER_REWARDS]: 'Member Rewards',
        [PAYMENT_METHODS.SAVED_CARD]: 'Saved Card',
        [PAYMENT_METHODS.APPLE_PAY]: 'Apple Pay',
        [PAYMENT_METHODS.GOOGLE_PAY]: 'Google Pay',
        [PAYMENT_METHODS.PAY_LATER]: {
          [SALE_TYPES.DINE_IN]: 'Table Service',
          [SALE_TYPES.TABLE_ORDER]: 'Table Service',
          [SALE_TYPES.PICKUP]: 'Pay on Collection',
          [SALE_TYPES.TAKE_AWAY]: 'Pay on Collection',
          [SALE_TYPES.DRIVE_THRU]: 'Pay on Collection',
          [SALE_TYPES.WEB_ORDERING]: 'Pay on Collection',
          [SALE_TYPES.DELIVERY]: 'Pay on Delivery',
        },
      },
      statusSteps: {
        [ORDER_SUBMITTED]: {
          incomplete: 'Submitting Order',
          complete: 'Order Submitted',
        },
        [RECEIVED_AT_STORE]: {
          incomplete: 'Receiving at Store',
          complete: 'Received at Store',
        },
        [PROCESSED_AT_STORE]: {
          incomplete: 'Processing at Store',
          complete: 'Preparing Order',
        },
        [READY_TO_COLLECT]: {
          incomplete: 'Readying Order',
          complete: {
            [SALE_TYPES.PICKUP]: 'Ready for Pick Up',
            [SALE_TYPES.WEB_ORDERING]: 'Ready to Collect',
            [SALE_TYPES.DELIVERY]: 'Order With Driver', // NOTE: only for client managed delivery
            [SALE_TYPES.DINE_IN]: 'Ready',
            [SALE_TYPES.TAKE_AWAY]: 'Ready to Collect',
          },
        },
        [PICKED_UP_BY_DRIVER]: {
          incomplete: 'Ready for Driver',
          complete: 'Collected by Driver',
        },
        [DELIVERED]: {
          incomplete: 'Order in Transit',
          complete: 'Order Delivered',
        },
      },
      paymentMethodUnavailableReason: {
        [PAYMENT_METHOD_UNAVAILABLE_REASON.NOT_ENABLED]: 'Not enabled',
        [PAYMENT_METHOD_UNAVAILABLE_REASON.INSUFFICIENT_BALANCE]: 'Insufficient balance',
        [PAYMENT_METHOD_UNAVAILABLE_REASON.MEMBER_MISSING]: 'Please sign in to use',
        [PAYMENT_METHOD_UNAVAILABLE_REASON.MEMBER_INVALID]: 'Please verify to use',
        [PAYMENT_METHOD_UNAVAILABLE_REASON.SALE_TYPE_NOT_COMPATIBLE]:
          'Not available with order type',
        [PAYMENT_METHOD_UNAVAILABLE_REASON.OFFER_NOT_COMPATIBLE]: 'Not available with offer',
        [PAYMENT_METHOD_UNAVAILABLE_REASON.CART_NOT_COMPATIBLE]:
          'Some cart items cannot be redeemed',
        [PAYMENT_METHOD_UNAVAILABLE_REASON.GIFT_CARD_INACTIVE]: 'Card not activated',
        // [PAYMENT_METHOD_UNAVAILABLE_REASON.TOTAL_INVALID]: '',
        [PAYMENT_METHOD_UNAVAILABLE_REASON.DELIVERY_PROVIDER_NOT_COMPATIBLE]:
          'Not available with this delivery provider',
      },
      purchaserDetailInvalid: {
        name: 'Not Valid',
        familyName: 'Not Valid',
        mobile: 'Not Valid',
        email: 'Not Valid',
      },
      timeSummary: {
        asap: 'ASAP',
        scheduleForLater: 'Schedule for later',
        selectPickupTime: 'Select pickup time',
      },
      completedOrderMessage: {
        [SALE_TYPES.PICKUP]: '',
        [SALE_TYPES.DELIVERY]: '',
        [SALE_TYPES.DINE_IN]: '',
        [SALE_TYPES.TAKE_AWAY]: '',
        [SALE_TYPES.CATERING]: 'Order Confirmed',
        [SALE_TYPES.TABLE_ORDER]: '',
      },
      applyPromoCode: {
        notFound: 'Promotion not found',
        failure: 'Problem applying promotion code',
        itemAdded: 'Promotion item added to cart',
        success: 'Promotion applied',
        coupons: 'Coupons',
        offersPresent: 'Apply voucher or promo code',
        offersAbsent: 'Apply promo code',
      },
      selectOfferFailed: {
        unavailable: 'Offer unavailable',
        item: 'Offer item not available at this store',
        default: 'Could not select offer',
      },
      failureReason: {
        [FAILURE_REASON.FETCH_FAILED]: 'Unable to contact server',
        [FAILURE_REASON.LOCATION_OFFLINE]: 'Location is offline',
        [FAILURE_REASON.LOCATION_CLOSED]: 'Location closed',
        [FAILURE_REASON.UNKNOWN]: 'Problem with order',
      },
      stockBalanceThreshold: {
        [STOCK_BALANCE_THRESHOLDS.STOCK_BALANCE_THRESHOLD_0]: 'SOLD OUT',
        [STOCK_BALANCE_THRESHOLDS.STOCK_BALANCE_THRESHOLD_1]: 'LOW IN STOCK',
        // TODO: Confirm default strings for last 3 thresholds
        [STOCK_BALANCE_THRESHOLDS.STOCK_BALANCE_THRESHOLD_2]: '',
        [STOCK_BALANCE_THRESHOLDS.STOCK_BALANCE_THRESHOLD_3]: '',
        [STOCK_BALANCE_THRESHOLDS.STOCK_BALANCE_THRESHOLD_4]: '',
      },
      memberVerificationRequirment: 'You must be a verified member in order to place an order',
      guestOrderMessageBeginning: 'You must',
      signInLink: ' Sign In ',
      guestOrderMessageConjuction: 'or',
      registerLink: ' Register ',
      guestOrderMessageEnding: 'to place an order',
      itemOutOfStockMessage: 'This Item Is No Longer Available',
      payWithMultiplePayment: 'Pay with multiple payment methods',
      rememberCard: 'remember card',
      doNotRememberCard: 'do not remember card',
      rewardsAvailable: 'Available: ',
      itemUnavailable: 'One or more items are no longer available',
      // itemOutOfStockAlertMessage: 'Only {{itemBalance}} of this item is available',
      itemQuantityWarningMessage: 'Only {{newItemQuantity}} x {{someLabel}} left',
      stagedChoiceSetItemQuantityWarningMessage:
        "You've added all the remaining quantities of this item",
      energy: {
        kilojouleSuffix: 'kJ',
        calorieSuffix: 'kcal',
      },
      accountPage: {
        stampcard: {
          progressCounter: '{{earned}}/{{max}}',
        },
        buttons: {
          topUp: 'Top up balance',
          scanCard: 'Scan card',
        },
        creditLoyalty: {
          progressMessage: "You're {{points}} away from $10 member money",
        },
      },
      loyaltyMigration: {
        landingPage: {
          subtitle:
            'Please provide your current account details so we can proceed with updating your membership',
          submitButton: 'Verify Account',
          loginPretext: 'Already an updated member?',
          loginText: 'Login',
        },
        detailsPage: {
          subtitle: 'Please share some additional information with us',
          submitButton: 'Update Details',
        },
        signInPage: {
          subtitle:
            "Thank you for updating your account details. We'll be sending you an email shortly where you can verify your account.",
        },
        claimPointsPage: {
          subtitle: 'Enter details below from your previous membership to claim points',
          submitButton: 'Claim Membership',
        },
        alreadyLoggedInMessage: 'You are already logged in.',
      },
      pointsEarned: 'Earned', //todo convert for global strings
      pointsRedeemed: 'Redeemed', //todo convert for global strings
      dateTimeLabel: 'Date & Time',
      orderTotalPaymentSummaryLabel: 'Remaining',
      buttons: {
        resendVerification: 'Resend Verification',
        home: 'Home',
      },
      snackbarMessages: {
        verifyPinRequired: 'Verification PIN required',
        success: 'Success',
        pointsClaimed: 'Membership points claimed successfully.',
        cannotCompleteVerification: 'Could not complete verification.',
        accountDeleted: 'Account Deleted! Signed Out...',
        accountVerified: 'Your account has now been verified...',
        deliveryTimeUpdate: 'Delivery time updated to reflect cart total',
      },
      deliveryTimeUpdateMessage:
        'Your estimated delivery time has been updated. Would you like to continue?',
      zxcvbn: {
        'Straight rows of keys are easy to guess': 'Straight rows of keys are easy to guess',
        'Short keyboard patterns are easy to guess': 'Short keyboard patterns are easy to guess',
        'Use a longer keyboard pattern with more turns':
          'Use a longer keyboard pattern with more turns',
        'Repeats like "aaa" are easy to guess': 'Repeats like "aaa" are easy to guess',
        'Repeats like "abcabcabc" are only slightly harder to guess than "abc"':
          'Repeats like "abcabcabc" are only slightly harder to guess than "abc"',
        'Sequences like abc or 6543 are easy to guess':
          'Sequences like abc or 6543 are easy to guess',
        'Recent years are easy to guess': 'Recent years are easy to guess',
        'Dates are often easy to guess': 'Dates are often easy to guess',
        'This is a top-10 common password': 'This is a top-10 common password',
        'This is a top-100 common password': 'This is a top-100 common password',
        'This is a very common password': 'This is a very common password',
        'This is similar to a commonly used password':
          'This is similar to a commonly used password',
        'A word by itself is easy to guess': 'A word by itself is easy to guess',
        'Names and surnames by themselves are easy to guess':
          'Names and surnames by themselves are easy to guess',
        'Common names and surnames are easy to guess':
          'Common names and surnames are easy to guess',
      },
    },
  },
  jp: {
    translation: {
      changeLanguageInfo: 'ご希望の言語を選択してください。',
      changeLanguageHeader: '言語の変更',
      languages: {
        en: 'English',
        jp: '日本語',
        es: 'Español',
      },
    },
  },
};
export default strings;
