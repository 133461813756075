import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import StandardButton from '../components/StandardButton';
import { RedcatApiHandler } from 'polygon-utils';
import { enqueueErrorSnackbar, enqueueSuccessSnackbar } from '../utils/snackbar';
import { syncMember } from '../thunks/syncMember';
import { Formik, Form, FormikHelpers } from 'formik';

import * as Yup from 'yup';
import setLocalValue from '../utils/setLocalValue';
import { IN_DEVELOPMENT } from '../constants';
import ReCAPTCHA from 'react-google-recaptcha';
import getProfileFields from '../selectors/getProfileFields';
import getRegisterProfileFields from '../selectors/getRegisterProfileFields';
import getProfile from '../selectors/getProfile';

import getThemeLookup from '../selectors/getThemeLookup';
import getDeviceTypeMobile from '../selectors/getDeviceTypeMobile';
import RegistrationFields from '../components/RegistrationFields';
import Text from '../components/Text';
import SendVerificationButton from '../components/SendVerificationButton';
import { INPUT_FIELDS, CURRENT_PASSWORD_FIELD } from '../constants/inputfields';

export const CHANGE_PASSWORD_ROUTE = '/change-password';

type ResetYourPasswordParams = {
  Code?: string;
  Password: string;
  ConfirmPassword: string;
  reCaptchaToken?: string;
};
const ChangePasswordScreen = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const p = useAppSelector(getThemeLookup);
  const profileFields = useAppSelector(getProfileFields);
  const registrationFields = useAppSelector(getRegisterProfileFields);
  const deviceTypeMobile = useAppSelector(getDeviceTypeMobile);
  const profile = useAppSelector(getProfile);
  const isCurrentPasswordRequired = useAppSelector(
    state => state.config.enableRequiredCurrentPasswordForUpdate,
  );
  const minimumPasswordLength = useAppSelector(state => state.config.minimumPasswordLength);

  const confirmPasswordField =
    profileFields && profileFields.find(field => field.id === INPUT_FIELDS.ConfirmPassword);

  const isConfirmPasswordRequired =
    confirmPasswordField &&
    confirmPasswordField?.registerMode !== 'disabled' &&
    confirmPasswordField?.editMode !== 'hidden';

  const confirmPasswordMode = profileFields?.find(
    ({ id }) => id === 'ConfirmPassword',
  )?.registerMode;

  const schemas: ResetYourPasswordSchema = {
    CurrentPassword: isCurrentPasswordRequired
      ? Yup.string().required('Required')
      : Yup.string().optional(),
    Password: Yup.string().required('Required').min(minimumPasswordLength, 'Too Short'),
    ConfirmPassword: Yup.string()
      .test('required', 'Required', function (value) {
        if (!isConfirmPasswordRequired) return true;
        return Boolean(value?.trim());
      })
      .test('too-short', 'Too Short', function (value) {
        if (!isConfirmPasswordRequired) return true;
        return Boolean(value && value.length >= minimumPasswordLength);
      })
      .test('passwords-match', 'Passwords do not match', function (value) {
        if (!isConfirmPasswordRequired) return true;
        return value === this.parent.Password;
      }),
  };

  const validationSchema = Yup.object().shape(schemas);

  // Function to get initial values of the form
  const getInitialValues = () => {
    const initialValues: any = { Password: '' };

    if (isCurrentPasswordRequired) {
      initialValues.CurrentPassword = '';
    }
    if (isConfirmPasswordRequired) {
      initialValues.ConfirmPassword = '';
    }
    return initialValues;
  };

  const submit = (values: FormikFields, formikHelpers: FormikHelpers<FormikFields>) => {
    const { setSubmitting, resetForm } = formikHelpers;

    const path = '/api/v1/profile';
    const body = { Password: values.Password } as {
      Password: string;
      ConfirmPassword?: string;
      CurrentPassword?: string;
    };
    // 'ConfirmPassword' should not be included when that field's mode is 'disabled'
    if (isConfirmPasswordRequired) body.ConfirmPassword = values.ConfirmPassword;
    if (confirmPasswordMode === 'optional') body.ConfirmPassword = values.Password;
    if (isCurrentPasswordRequired && values.CurrentPassword)
      body.CurrentPassword = values.CurrentPassword;
    RedcatApiHandler.authorisedFetch({
      path,
      method: 'PUT',
      body,
    })
      .then(response => {
        if (IN_DEVELOPMENT && response.token) {
          setLocalValue('memberAuthToken', response.token);
        }

        dispatch(syncMember());

        if (response.success) {
          enqueueSuccessSnackbar(t('updateSuccess'));
          setSubmitting(false);
          resetForm({ values });
        }
      })
      .catch(err => {
        enqueueErrorSnackbar(err);
        setSubmitting(false);
      });
  };

  return (
    <div style={{ margin: '0 20px' }}>
      {!profile?.verified ? (
        <div style={{ textAlign: 'center', padding: '20px 0' }}>
          <Text value={t('profileVerificationText')} style={{ fontSize: 14 }} />
          <SendVerificationButton containerStyle={{ paddingTop: 20 }} />
        </div>
      ) : null}
      <Formik
        validationSchema={validationSchema}
        initialValues={getInitialValues()}
        onSubmit={submit}
        enableReinitialize={true}
        validateOnMount
      >
        {({ handleSubmit, touched, isSubmitting, isValid }) => {
          return (
            <Form onSubmit={handleSubmit} style={styles.registrationForm}>
              <>
                {isCurrentPasswordRequired && (
                  <RegistrationFields
                    field={{ ...CURRENT_PASSWORD_FIELD, label: t('currentPassword') }}
                  />
                )}
                {registrationFields &&
                  registrationFields.visible
                    .filter((field: FormikFields) => field.id === INPUT_FIELDS.Password)
                    .map((field: RegisterFields, index: any) => (
                      <RegistrationFields
                        key={index}
                        field={{ ...field, label: t('newPassword'), mode: '' }}
                        showStrengthBar={isConfirmPasswordRequired ? false : true}
                      />
                    ))}

                {isConfirmPasswordRequired &&
                  profileFields &&
                  profileFields
                    .filter((field: FormikFields) => field.id === INPUT_FIELDS.ConfirmPassword)
                    .map((field: RegisterFields, index: any) => (
                      <RegistrationFields
                        key={index}
                        field={{ ...field, label: t('confirmPassword'), mode: '' }}
                        showStrengthBar={true}
                      />
                    ))}

                <div className="position-relative mt-4">
                  <StandardButton
                    label={t('button.updatePassword')}
                    containerStyle={styles.button}
                    disabled={Boolean(isSubmitting || !isValid || !Object.keys(touched).length)}
                    themeKey="signInSubmitButton"
                    onClick={handleSubmit}
                  />
                </div>
              </>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const styles: Styles = {
  button: {
    borderRadius: 5,
    height: 40,
    padding: 0,
  },

  column: {
    minWidth: 250,
  },
  columnMobile: {
    padding: '20px 0 0 0',
  },

  verticalSeperator: {
    width: 1.5,
    marginLeft: 40,
    marginRight: 40,
  },
  registrationForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'strech',
    justifyContent: 'space-between',
    paddingTop: 40,
  },
};

export default ChangePasswordScreen;
