import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import Line from './Line';
import { OrderingSelectors } from 'polygon-ordering';
import { SALE_TYPE } from '../libs/polygon-ordering/src/constants/saleType';
import { PAYMENT_METHOD } from '../libs/polygon-ordering/src/constants/paymentMethod';

const {
  getOrderTotals,
  getAvailablePaymentMethods,
  getEnabledPaymentMethods,
  getSaleType,
  getSelectedOffer,
  getSelectedPaymentMethods,
} = OrderingSelectors;

const PointsSummary: React.FC = () => {
  const { t } = useTranslation();
  const orderTotals = useAppSelector(getOrderTotals);
  const selectedOffer = useAppSelector(getSelectedOffer);
  const enabledPaymentMethods = useAppSelector(getEnabledPaymentMethods);
  const availablePaymentMethods = useAppSelector(getAvailablePaymentMethods);
  const selectedPaymentMethods = useAppSelector(getSelectedPaymentMethods);
  const saleType = useAppSelector(getSaleType);

  // Function to check whether payment method is available or not
  const pmAvailable = (method: PAYMENT_METHOD) => availablePaymentMethods.includes(method);
  // Function to check whether payment method is enabled or not
  const pmEnabled = (method: PAYMENT_METHOD) => enabledPaymentMethods.includes(method);
  // Function to check whether payment method is selected by user or not
  const pmSelected = (method: PAYMENT_METHOD) => {
    const methodsName = selectedPaymentMethods.map(m => m.method);
    return methodsName.includes(method);
  };

  const noOfferPresent = !selectedOffer;

  const isDelivery = saleType === SALE_TYPE.DELIVERY;

  // Showing points cost if paying using member points is available, no offers, order is not delivery and we have the order points price data
  const showPointsCost =
    pmAvailable(PAYMENT_METHOD.MEMBER_POINTS) &&
    noOfferPresent &&
    !isDelivery &&
    Boolean(orderTotals!.pointsPrice);

  // Showing points award if user is not paying using member points, member points is enabled in PC, no offers and we have the order points price data
  const showPointsAward =
    !pmSelected(PAYMENT_METHOD.MEMBER_POINTS) &&
    pmEnabled(PAYMENT_METHOD.MEMBER_POINTS) &&
    noOfferPresent &&
    Boolean(orderTotals!.pointsAward);

  // Showing rewards award if user is not paying using member rewards, member rewards is enabled in PC and we have the order points price data
  const showRewardsAward =
    !pmSelected(PAYMENT_METHOD.MEMBER_REWARDS) &&
    pmEnabled(PAYMENT_METHOD.MEMBER_REWARDS) &&
    Boolean(orderTotals!.rewardsAward);

  console.log({ orderTotals });

  return (
    <div>
      {showPointsCost && (
        <Line
          label={t('totals.pointsPrice')}
          amount={Math.round(orderTotals!.pointsPrice * 100)}
          deemphasised
          skipFormatting
        />
      )}
      {showPointsAward && !showRewardsAward && (
        <Line
          label={t('totals.pointsAward')}
          amount={Math.round(orderTotals!.pointsAward * 100)}
          skipFormatting
          deemphasised
          hideSymbol
        />
      )}
      {showRewardsAward && !showPointsAward && (
        <Line
          label={t('totals.rewardsAward')}
          amount={orderTotals!.rewardsAward}
          deemphasised
          hideSymbol
        />
      )}
    </div>
  );
};

export default PointsSummary;
