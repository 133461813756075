import React, { FC } from 'react';
import CheckWrapper from './CheckWrapper';
import { createTheme, ThemeProvider } from '@mui/system';
import RedcatImage from './RedcatImage';
import { useAppSelector } from '../app/hooks';
import getThemeLookup from '../selectors/getThemeLookup';
import { CONTAINER_PROPERTIES } from '../utils/theme';
import combineStyles from '../utils/combineStyles';
import Text from './Text';
import { OrderingHooks } from 'polygon-ordering';

const { useFormattedCurrency } = OrderingHooks;

interface ChoiceWithQuantity extends Choice {
  quantity?: number;
}
type Props = {
  choice: ChoiceWithQuantity;
  onClick: () => void;
  checked: boolean;
  label?: JSX.Element;
  size?: number;
  displayType?: string;
  disabled?: boolean;
  price: number;
};

const CheckBox: FC<Props> = ({
  choice,
  onClick,
  checked,
  label,
  size,
  displayType,
  disabled,
  price,
}) => {
  const priceShowing = useFormattedCurrency({ cents: price });
  const p = useAppSelector(getThemeLookup);
  const overideTheme = createTheme({
    palette: {
      size: size,
    },
  });
  const iconVisible =
    displayType === 'list' || displayType === 'checkbox' || displayType === undefined;
  const displayGrid = displayType === 'grid';

  return (
    <ThemeProvider theme={overideTheme}>
      <CheckWrapper onClick={onClick} disabled={disabled}>
        <div
          style={{
            display: 'flex',
            gap: 10,
            flexDirection: displayGrid ? 'column' : 'row',
            alignItems: 'center',
            // justifyContent: 'center',
          }}
        >
          {iconVisible && <div className={`check-wrapper ${checked && 'checked'}`}></div>}

          {displayGrid && (
            <div style={{ width: 150, height: 100 }}>
              <RedcatImage
                alt={choice.name}
                imagePath={choice?.images?.default}
                containerStyle={styles.imageContainer}
                imageStyle={{
                  ...styles.imageStyle,
                }}
              />
            </div>
          )}
          <div
            style={
              displayGrid
                ? combineStyles(
                    styles.labelContainer,
                    checked && p('itemChoiceActive', CONTAINER_PROPERTIES),
                  )
                : {}
            }
          >
            {label}
            {displayGrid && (
              <div style={{ flex: 1, textAlign: 'center' }}>
                {price > 0 && <Text>{`+ ${priceShowing}`}</Text>}
              </div>
            )}
          </div>
        </div>
      </CheckWrapper>
    </ThemeProvider>
  );
};

export default CheckBox;
const styles: Styles = {
  imageContainer: {
    height: 100,
    minWidth: 150,
    maxWidth: 150,
  },
  imageStyle: {
    width: '100%',
    height: '100%',
  },
  labelContainer: {
    padding: 10,
    borderRadius: 10,
    width: 150,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
};
