import React, { createContext, useState, useEffect, ReactElement } from 'react';
import lodash from 'lodash';
import {
  OrderingSelectors,
  tallyAndValidateChoiceSets,
  choiceSetsWithQuantities,
  tallyAndValidateNestedChoicesets,
  nestedChoiceSetsWithQuantities,
  calculateChoiceSetTotals,
  extractChoiceWithQuantityFromNestedPurchased,
} from 'polygon-ordering';
import { useAppSelector } from '../app/hooks';
import sortChoices from '../libs/polygon-ordering/src/utils/ordering/sortChoices';

const {
  getOpenPurchase,
  getOpenPurchasePreviouslyStaged,
  getStagedPurchases,
  getNestedItemStockBalancesData,
  getMenu,
} = OrderingSelectors;

export type NestedIngredientsStages = SDict<string | SDict<ChoiceWithQuantity[]>> | undefined;
export interface INestedChoiceSetsContext {
  showNestedItem: boolean;
  setShowNestedItem: (v: boolean) => void;
  showChoices: boolean;
  setShowChoices: (v: boolean) => void;
  stages: NestedIngredientsStages;
  setStages: (v: NestedIngredientsStages) => void;
  purchased: NestedChoiceSelections | undefined;
  setPurchased: (v: NestedChoiceSelections | undefined) => void;
  isSelectionValid: boolean; //'add to item' button enable/disable
  setIsSelectionValid: (v: boolean) => void;
  nestedItemInValid: boolean; //'add to cart' button enable/disable
  setNestedItemInValid: (v: boolean) => void;
  nestedChoiceSetsTotal: number; //choicesets total price
  setNestedChoiceSetsTotal: (v: number) => void;
  scrollDownIndicator?: () => void;
}

export const NestedChoiceSetsContext = createContext<INestedChoiceSetsContext | null>(null);

export default ({ children }: { children: ReactElement }) => {
  const [showNestedItem, setShowNestedItem] = useState<boolean>(false);
  const [showChoices, setShowChoices] = useState<boolean>(false);
  const [stages, setStages] = useState<NestedIngredientsStages>(undefined);
  const [purchased, setPurchased] = useState<NestedChoiceSelections>(undefined);
  const [isSelectionValid, setIsSelectionValid] = useState(false);
  const [nestedItemInValid, setNestedItemInValid] = useState(false);
  const [nestedChoiceSetsTotal, setNestedChoiceSetsTotal] = useState(0);

  const state = {
    showNestedItem,
    setShowNestedItem,
    showChoices,
    setShowChoices,
    stages,
    setStages,
    purchased,
    setPurchased,
    isSelectionValid,
    setIsSelectionValid,
    nestedItemInValid,
    setNestedItemInValid,
    nestedChoiceSetsTotal,
    setNestedChoiceSetsTotal,
  };
  const openPurchase = useAppSelector(getOpenPurchase);
  const choiceSets = openPurchase?.choiceSets;

  const previouslyStaged = useAppSelector(getOpenPurchasePreviouslyStaged);
  const [stockBalanceDataMap] = useAppSelector(getNestedItemStockBalancesData(openPurchase));
  const prePurchase = usePreSelections(openPurchase, choiceSets, stockBalanceDataMap);
  useEffect(() => {
    // don't redo preselections if there are already selections
    if (purchased) return;
    if (!previouslyStaged) setPurchased(prePurchase);
  }, [prePurchase, previouslyStaged]);

  const menu = useAppSelector(getMenu);
  const items = menu?.items;
  const allChoiceSets = menu?.choiceSets as ChoiceSets | NestedChoiceSets;

  const stagedPurchases = useAppSelector(getStagedPurchases);
  useEffect(() => {
    // get the key of the selected nested choice set
    const currentSetIndx = (stages || {})[0] as string;
    let validatedSets: ValidatedChoiceSet[] = [];

    if (currentSetIndx && choiceSets) {
      // get the definition of the choiceset with that key
      const set = choiceSets.find(s => s.key === currentSetIndx);
      const nested = Object.keys(stages || {}).length;
      const currentSets =
        nested === 3
          ? (items || {})[(stages || {})[1] as string].choiceSets.map(e => allChoiceSets[e])
          : [set as ChoiceSet];
      let currentSelections = (stages || {})[nested - 1];
      currentSelections = typeof currentSelections === 'string' ? {} : currentSelections;
      validatedSets = tallyAndValidateChoiceSets(
        choiceSetsWithQuantities(
          currentSets,

          lodash.mapValues(currentSelections as { [key: string]: ChoiceWithQuantity[] }, values => {
            return lodash.map(values, v => v.id);
          }),
          nested,
        ),
      );
    }

    if (validatedSets) {
      setIsSelectionValid(validatedSets.filter(c => !c.valid).length === 0);
    }
  }, [stages]);
  const [selectionValid, choicesetsTotalPrice] = usePurchasedItemStatus(
    purchased,
    choiceSets ?? [],
  );

  useEffect(() => {
    setNestedItemInValid(selectionValid);
    setNestedChoiceSetsTotal(choicesetsTotalPrice);
  }, [selectionValid, choicesetsTotalPrice]);

  useEffect(() => {
    // don't redo preselections if there are already selections
    if (purchased) return;
    if (previouslyStaged) {
      if (openPurchase && stagedPurchases) {
        const stagedPurchase = stagedPurchases.find(p => p.id === openPurchase.id);
        setPurchased(stagedPurchase?.choiceSelections as NestedChoiceSelections);
      }
    }
  }, [previouslyStaged]);

  return (
    <NestedChoiceSetsContext.Provider value={state}>{children}</NestedChoiceSetsContext.Provider>
  );
};

//validate item & calculate choicesets price for 'add to cart' button
const usePurchasedItemStatus = (
  purchased: NestedChoiceSelections,
  choiceSets: (ValidatedChoiceSet | ValidatedNestedChoiceSet)[],
): [boolean, number] => {
  const choiceOrderingMethod = useAppSelector(state => state.ordering.config.choiceOrderingMethod);

  const [nestedItemInValid, setNestedItemInValid] = useState(false);
  const [nestedChoiceSetsTotal, setNestedChoiceSetsTotal] = useState(0);
  useEffect(() => {
    let choiceSelection: NestedChoiceSelections = {};
    if (Object.keys(purchased || {}).length > 0) {
      for (const p in purchased) {
        choiceSelection[p] = purchased[p];
      }
    }
    const cwq = nestedChoiceSetsWithQuantities(
      choiceSets,
      choiceSelection,
      choiceOrderingMethod || 'cheapest',
    );
    const validatedSets = tallyAndValidateNestedChoicesets(cwq);

    setNestedChoiceSetsTotal(
      // previouslyStaged
      //   ? 0
      //   :
      validatedSets.reduce((totalPrice, set) => {
        return (totalPrice += calculateChoiceSetTotals(
          set,
          choiceOrderingMethod || 'cheapest',
        ).moneyPrice);
      }, 0),
    );

    // validatedSets.reduce((totalPrice, set) => {
    //   return (totalPrice += calculateChoiceSetTotals(set, 'cheapest').moneyPrice);
    // }, 0);
    const inValid = !!validatedSets.filter(set => !set.valid).length;
    setNestedItemInValid(inValid);
  }, [purchased, choiceOrderingMethod]);
  return [nestedItemInValid, nestedChoiceSetsTotal];
};

export const isChoiceInStagedPurchase = (
  choiceId: string,
  parentChoiceSetId: string,
  purchased: NestedChoiceSelections,
  nestedStaging: NestedIngredientsStages,
  nested: boolean,
): [boolean, number | undefined] => {
  let isChoiceInPurchase = false,
    selectedQty = undefined;
  if (!purchased || !nestedStaging) return [isChoiceInPurchase, selectedQty];
  const path = nested
    ? [nestedStaging[0] as string, nestedStaging[1] as string, parentChoiceSetId]
    : (nestedStaging[0] as string);
  if (!lodash.has(purchased, path)) return [isChoiceInPurchase, selectedQty];
  const choices = lodash.get(purchased, path);
  const arrLength = (choices as ChoiceWithQuantity[]).filter(c => c.plucode === choiceId).length;
  isChoiceInPurchase = Boolean(arrLength);
  selectedQty = arrLength ? arrLength : undefined;
  return [isChoiceInPurchase, selectedQty];
};

export const isPurchasedChoiceSet = (
  purchased: NestedChoiceSelections | undefined,
  id?: string,
  stages?: { [key: string]: string | { [key: string]: ChoiceWithQuantity[] } },
): boolean => {
  let result: boolean = false;

  if (!Object.keys(purchased || {}).length) return result;
  if (!stages) {
    for (const p in purchased) {
      result = p === id;
      if (result) break;
    }
  } else {
    for (const item in purchased) {
      if (Array.isArray(purchased[item])) {
        result = item === stages[0];
        if (result) return result;
      }
      if (item === stages[0]) {
        for (const i in purchased[item]) {
          if (id) {
            result = i === id;
          } else {
            if (typeof stages[1] === 'string') result = i === stages[1];
          }

          if (result) {
            break;
          }
        }
      }
      if (result) break;
    }
  }

  return result;
};

// TODO: combine this into one function
export const calculateChoiceSetPrice = (
  selection: SDict<SDict<ChoiceWithQuantity[]>> | ChoiceWithQuantity[],
  choiceSet: ValidatedChoiceSet,
  nestedIngredients: boolean,
  choiceOrderingMethod: ChoiceOrderingMethod,
): number => {
  let result = 0;
  if (nestedIngredients) {
    if (!selection) return result;
    // typescript throwing a few hissy fits with this function so did some dodgy casting
    // foreach nested item (choice) in choiceset
    Object.entries(selection).forEach(([choiceId, choiceVals]) => {
      // not sure what the correct type to use here is
      const choice = choiceSet.choices.find(c => c.id === choiceId) as unknown as NestedChoice;
      if (!choice) return;
      result += choice.baseMoneyPrice;
      // foreach nested choiceset in choice item
      Object.entries(choiceVals as SDict<ChoiceWithQuantity[]>).forEach(
        ([nestedChoiceSetId, nestedChoiceSetVals]) => {
          const nestedChoiceSet = choice.choiceSets.find(c => c.id === nestedChoiceSetId);
          // foreach choice in nested choice set
          // duct-tape
          result += sortChoices(nestedChoiceSetVals, choiceOrderingMethod).reduce(
            (sum, nestedChoice, i) =>
              sum + (i >= (nestedChoiceSet?.free ?? 0) ? nestedChoice.baseMoneyPrice : 0),
            0,
          );
        },
      );
    });
  } else {
    result += ((selection as ChoiceWithQuantity[]) || []).reduce(
      (sum: number, choice: ChoiceWithQuantity, i) =>
        sum + (i >= choiceSet.free ? choice.baseMoneyPrice : 0),
      0,
    );
  }
  return result;
};

export const createPurchaseFromStages = (
  stagedSet: NestedIngredientsStages,
): NestedChoiceSelections | SDict<ChoiceWithQuantity[]> => {
  return Object.keys(stagedSet || {}).length === 3
    ? {
        [stagedSet!['0'] as string]: {
          [stagedSet!['1'] as string]: stagedSet!['2'] as SDict<ChoiceWithQuantity[]>,
        },
      }
    : (stagedSet!['1'] as SDict<ChoiceWithQuantity[]>);
};

//calculate preselect choice quantity when populate preselection
const calculateChoiceQuantityInPreselect = (
  preSelect: NestedChoiceSelections,
  choiceSets: (ValidatedChoiceSet | ValidatedNestedChoiceSet)[] | undefined,
  stockBalanceDataMap: SDict<StockBalanceData> | undefined,
): SDict<number | undefined> | undefined => {
  if (!stockBalanceDataMap) return;
  if (!preSelect) return lodash.mapValues(stockBalanceDataMap, 'cartAdjustedBalance');

  const selectedChoices = extractChoiceWithQuantityFromNestedPurchased(
    preSelect,
    choiceSets as (ChoiceSet | NestedChoiceSet)[],
  );
  const preSelectChoices = (selectedChoices || []).reduce((acc, current) => {
    return {
      ...acc,
      [current.plucode]: lodash.has(acc, current.plucode) ? acc[current.plucode] + 1 : 1,
    };
  }, {} as SDict<number>);

  const cartAdjustedBalanceMap = lodash.mapValues(stockBalanceDataMap, 'cartAdjustedBalance');

  return lodash.mapValues(cartAdjustedBalanceMap, (value, key) => {
    if (lodash.has(preSelectChoices, key)) {
      return value ? value - preSelectChoices[key] : undefined;
    } else {
      return value;
    }
  });
};

//process pre selected item
const usePreSelections = (
  openPurchase: PurchaseWithTotals | undefined,
  choiceSets: (ValidatedChoiceSet | ValidatedNestedChoiceSet)[] | undefined,
  stockBalanceDataMap?: SDict<StockBalanceData>,
) => {
  const [prePurchased, setPrePurchased] = useState<NestedChoiceSelections>();
  useEffect(() => {
    if (choiceSets) {
      let preSelect: NestedChoiceSelections;
      choiceSets.forEach(set => {
        const preSelectQuantityMap = calculateChoiceQuantityInPreselect(
          preSelect,
          choiceSets,
          stockBalanceDataMap,
        );
        const preSelectNestedChoices = set.choices.filter(
          c =>
            c.selected &&
            (!lodash.has(preSelectQuantityMap, c.plucode) ||
              (lodash.has(preSelectQuantityMap, c.plucode) &&
                (preSelectQuantityMap![c.plucode] || 0) > 0)),
        );
        if (preSelectNestedChoices.length) {
          if ('nestedIngredients' in set) {
            (preSelectNestedChoices as NestedChoiceWithQuantity[]).forEach(
              (nestedChoice: NestedChoiceWithQuantity) => {
                let nestedChoiceSelection: {} | undefined = {};
                const nestedSets = nestedChoice.choiceSets ?? [];

                nestedSets.forEach(s => {
                  const { choices: nestedChoices } = s;
                  const selectedChoices = nestedChoices.filter(c => c.selected);
                  const preQty =
                    selectedChoices.length === 1 && s.min
                      ? Math.min(s.min, s.individualMax || s.min)
                      : 1;

                  if (preQty > 1) {
                    if (
                      lodash.has(preSelectQuantityMap, selectedChoices[0].plucode) &&
                      (preSelectQuantityMap as SDict<number>)[selectedChoices[0].plucode] !==
                        undefined &&
                      preQty < (preSelectQuantityMap![selectedChoices[0].plucode] as number)
                    ) {
                      nestedChoiceSelection = {
                        ...nestedChoiceSelection,
                        [s.id]: Array.from({ length: preQty }, () => selectedChoices[0]),
                      };
                    }
                  } else {
                    nestedChoiceSelection = {
                      ...nestedChoiceSelection,
                      [s.id]: selectedChoices.filter(
                        c =>
                          preSelectQuantityMap === undefined ||
                          preSelectQuantityMap[c.plucode] === undefined ||
                          !((preSelectQuantityMap[c.plucode] as number) < preQty),
                      ),
                    };
                  }
                });
                const selections = Object.entries(nestedChoiceSelection || {}).reduce(
                  (acc, [key, value]) => ({
                    ...acc,
                    [key]: (value as ChoiceWithQuantity[]).map(v => v.id),
                  }),
                  {},
                );
                const validatedSets = tallyAndValidateChoiceSets(
                  choiceSetsWithQuantities(nestedSets, selections, 0),
                );
                const validated = validatedSets.reduce(
                  (acc, current) => acc && current.valid,
                  true,
                );
                if (validated)
                  preSelect = {
                    ...preSelect,
                    [set.key]: { [nestedChoice.id]: nestedChoiceSelection },
                  };
              },
            );
          } else {
            const preChoices = set.choices.filter(c => c.selected);
            const preQty =
              preChoices.length === 1 && set.min
                ? Math.min(set.min, set.individualMax || set.min)
                : 1;
            if (preChoices.length > 0) {
              const selection =
                preQty > 1 ? Array.from({ length: preQty }, () => preChoices[0]) : preChoices;
              const validatedSet = tallyAndValidateChoiceSets(
                choiceSetsWithQuantities([set], { [set.key]: selection.map(s => s.id) }),
              )[0];

              if (validatedSet.valid)
                preSelect = {
                  ...preSelect,
                  [set.key]: selection,
                };
            }
          }
        }
      });
      setPrePurchased(preSelect);
    }
  }, [openPurchase]);

  return prePurchased;
};
