import { combineReducers, AnyAction, Reducer } from 'redux';

import facebookAnalyticsKey from './facebookAnalyticsKey';
import googleTagManagerKey from './googleTagManagerKey';
import googleAnalyticsKey from './googleAnalyticsKey';
import brandingFavicon from './brandingFavicon';
import collapsableChoiceSetsEnabled from './collapsableChoiceSetsEnabled';
import collapseOptionalChoiceSetsByDefault from './collapseOptionalChoiceSetsByDefault';

import disableCategoryCount from './disableCategoryCount';
import disableLoyalty from './disableLoyalty';
import disableMenuSearch from './disableMenuSearch';
import disableOffers from './disableOffers';
import disablePickupTimeSelection from './disablePickupTimeSelection';
import displayPointsAsWholeNumber from './displayPointsAsWholeNumber';
import enableDeliveryNotes from './enableDeliveryNotes';
import enableAutoselectFirstLocation from './enableAutoselectFirstLocation';
import enableFraudPreventionMessage from './enableFraudPreventionMessage';
import enableMenuLocationDetails from './enableMenuLocationDetails';
import enableOrderNotes from './enableOrderNotes';
import hideFreeChoiceMessage from './hideFreeChoiceMessage';
import enableReCaptcha from './enableReCaptcha';
import enableReCaptchaForForgetPassword from './enableReCaptchaForForgetPassword';
import enableThemeKeyHints from './enableThemeKeyHints';
import fallbackLocation from './fallbackLocation';
import flattenDeliverySurcharges from './flattenDeliverySurcharges';
import floatingScreenBackgroundImagePath from './floatingScreenBackgroundImagePath';
import geocoderCountryRestriction from './geocoderCountryRestriction';
import googleMapsApiKey from './googleMapsApiKey';
import headerBrandImagePath from './headerBrandImagePath';
import headerBrandingType from './headerBrandingType';
import headerBrandName from './headerBrandName';
import heroScreenImagePath from './heroScreenImagePath';
import hideLocationHours from './hideLocationHours';
import hideMenuItemSizeDescription from './hideMenuItemSizeDescription';
import hideTimesForSaleTypes from './hideTimesForSaleTypes';
import initialZoom from './initialZoom';
import locationAddressTemplate from './locationAddressTemplate';
import paymentGatewayPublicKey from './paymentGatewayPublicKey';
import reCaptchaSiteKey from './reCaptchaSiteKey';
import relaxMenuItemHeightConsistency from './relaxMenuItemHeightConsistency';
import stringOverrides from './stringOverrides';
import tableNumberLabel from './tableNumberLabel';
import themeOverrides from './themeOverrides';
import thresholdOrderingProviders from './thresholdOrderingProviders';
import enableStockPolling from './enableStockPolling';
import stockPollingInterval from './stockPollingInterval';
import googleLoginButtonClientKey from './googleLoginButtonClientKey';
import facebookLoginButtonClientId from './facebookLoginButtonClientId';
import appleLoginButtonClientKey from './appleLoginButtonClientKey';
import profileFields from './profileFields';
import oauthExtraFields from './oauthExtraFields';
import passwordComplexity from './passwordComplexity';
import termsAndConditionsLink from './termsAndConditionsLink';
import loyaltyDisplayAddReceipt from './loyaltyDisplayAddReceipt';
import creditLoyaltyThreshold from '../config/creditLoyaltyTreshold';
import hqGatewayKey from '../config/hqGatewayKey';
import fallbackClosingHours from '../config/fallbackClosingHours';
import fallbackOpeningHours from '../config/fallbackOpeningHours';
import deliveryTimeOpen from '../config/deliveryTimeOpen';
import deliveryTimeClose from '../config/deliveryTimeClose';

import scriptInjection from './scriptInjection';
import hideHeroScreenImage from './hideHeroScreenImage';
import tiktokAnalyticsKey from './tiktokAnalyticsKey';
import firebaseApiKey from './firebaseApiKey';
import firebaseAppId from './firebaseAppId';
import firebaseAuthDomain from './firebaseAuthDomain';
import firebaseMeasurementId from './firebaseMeasurementId';
import firebaseMessagingSenderId from './firebaseMessagingSenderId';
import firebaseProjectId from './firebaseProjectId';
import firebaseStorageBucket from './firebaseStorageBucket';
import purchaseUnits from './purchaseUnits';
import maxGiftCardValue from './maxGiftCardValue';
import loyaltyBarcodeView from './loyaltyBarcodeView';
import disabledLoyaltyRoutes from './disabledLoyaltyRoutes';
import stripeMerchantAccount from './stripeMerchantAccount';
import enableGiftcardPurchase from './enableGiftcardPurchase';
import enableLoyaltyWallet from './enableLoyaltyWallet';
import enableSelfDelete from './enableSelfDelete';
import enableBrandSelection from './enableBrandSelection';
import enableOnlyLoyalty from './enableOnlyLoyalty';
import loyaltySendVerificationEmail from './loyaltySendVerificationEmail';
import loyaltyEnableSmsVerification from './loyaltyEnableSmsVerification';
import loyaltyRequiresVerification from './loyaltyRequiresVerificationWeb';
import creditLoyaltyEnabled from './creditLoyaltyEnabled';
import enableTieredLoyalty from './enableTieredLoyalty';
import orderingScriptInjection from './orderingScriptInjection';
import loyaltyScriptInjection from './loyaltyScriptInjection';
import loyaltyHtmlHeadInjection from './loyaltyHtmlHeadInjection';
import reportingScriptInjection from './reportingScriptInjection';
import brazeAnalyticsKey from './brazeAnalyticsKey';
import brazeSdkEndpoint from './brazeSdkEndpoint';
import loyaltyPointsName from './loyaltyPointsName';
import loyaltyMoneyName from './loyaltyMoneyName';
import hybridCashPaymentEnabled from './hybridCashPaymentEnabled';
import stampcardConfig from './stampcardConfig';
import loyaltyMemberCardset from './loyaltyMemberCardset';
import enableLoyaltyMigration from './enableLoyaltyMigration';
import logoImagePath from './logoImagePath';
import enableAppleLogin from './enableAppleLogin';
import enableGoogleLogin from './enableGoogleLogin';
import enableFacebookLogin from './enableFacebookLogin';
import oAuthDetails from '../../libs/polygon-ordering/src/reducers/config/oAuthDetails';
import enableNonMemberPhoneNumberRequired from './enableNonMemberPhoneNumberRequired';
import enableRequiredCurrentPasswordForUpdate from './enableRequiredCurrentPasswordForUpdate';
import minimumPasswordLength from './minimumPasswordLength';
import disableLanguages from './disableLanguages';

const config: Reducer<ConfigState, AnyAction> = combineReducers({
  brazeAnalyticsKey,
  brazeSdkEndpoint,
  enableOnlyLoyalty,
  enableTieredLoyalty,
  creditLoyaltyEnabled,
  loyaltyPointsName,
  loyaltyMemberCardset,
  enableLoyaltyWallet,
  enableSelfDelete,
  enableGiftcardPurchase,
  stripeMerchantAccount,
  disabledLoyaltyRoutes,
  loyaltyBarcodeView,
  purchaseUnits,
  maxGiftCardValue,
  fallbackClosingHours,
  fallbackOpeningHours,
  deliveryTimeOpen,
  deliveryTimeClose,
  hqGatewayKey,
  creditLoyaltyThreshold,
  loyaltyDisplayAddReceipt,
  firebaseApiKey,
  firebaseAppId,
  firebaseAuthDomain,
  firebaseMeasurementId,
  firebaseMessagingSenderId,
  firebaseProjectId,
  firebaseStorageBucket,
  tiktokAnalyticsKey,
  googleTagManagerKey,
  facebookAnalyticsKey,
  facebookLoginButtonClientId,
  googleLoginButtonClientKey,
  appleLoginButtonClientKey,
  googleAnalyticsKey,
  brandingFavicon,
  collapsableChoiceSetsEnabled,
  collapseOptionalChoiceSetsByDefault,
  disableCategoryCount,
  disableLoyalty,
  disableMenuSearch,
  disableOffers,
  disablePickupTimeSelection,
  displayPointsAsWholeNumber,
  enableAutoselectFirstLocation,
  enableDeliveryNotes,
  enableMenuLocationDetails,
  enableFraudPreventionMessage,
  enableOrderNotes,
  hideFreeChoiceMessage,
  enableReCaptcha,
  enableReCaptchaForForgetPassword,
  enableThemeKeyHints,
  fallbackLocation,
  flattenDeliverySurcharges,
  floatingScreenBackgroundImagePath,
  geocoderCountryRestriction,
  googleMapsApiKey,
  headerBrandImagePath,
  headerBrandingType,
  headerBrandName,
  heroScreenImagePath,
  hideLocationHours,
  hideMenuItemSizeDescription,
  hideTimesForSaleTypes,
  initialZoom,
  locationAddressTemplate,
  paymentGatewayPublicKey,
  reCaptchaSiteKey,
  relaxMenuItemHeightConsistency,
  stringOverrides,
  tableNumberLabel,
  themeOverrides,
  thresholdOrderingProviders,
  profileFields,
  oauthExtraFields,
  passwordComplexity,
  termsAndConditionsLink,
  scriptInjection,
  orderingScriptInjection,
  loyaltyScriptInjection,
  loyaltyHtmlHeadInjection,
  reportingScriptInjection,
  hideHeroScreenImage,
  enableStockPolling,
  stockPollingInterval,
  loyaltySendVerificationEmail,
  loyaltyEnableSmsVerification,
  loyaltyRequiresVerification,
  enableBrandSelection,
  stampcardConfig,
  loyaltyMoneyName,
  hybridCashPaymentEnabled,
  enableLoyaltyMigration,
  logoImagePath,
  enableAppleLogin,
  enableGoogleLogin,
  enableFacebookLogin,
  oAuthDetails,
  enableNonMemberPhoneNumberRequired,
  enableRequiredCurrentPasswordForUpdate,
  minimumPasswordLength,
  disableLanguages,
});

export default config;
